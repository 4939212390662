import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

export default function BasicAlerts({
  width,
  spacing,
  showAlert = false,
  type,
  title,
  ...props
}) {
  return (
    <Stack spacing={2} margin="20px 0px">
      <Alert UncontrolledAlert {...props} severity={type}>
        {title}
      </Alert>
    </Stack>
  );
}
