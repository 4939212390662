import React, { Component } from "react";
import { Map, Marker, Polyline, GoogleApiWrapper } from "google-maps-react";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: null,
      courierLocation: null,
    };
  }
  componentDidMount() {
    this.trackCourierLocation();
  }

  trackCourierLocation = () => {
    setInterval(() => {
      const { startLocation, endLocation } = this.props;
      const lat =
        startLocation.lat +
        Math.random() * (endLocation.lat - startLocation.lat);
      const lng =
        startLocation.lng +
        Math.random() * (endLocation.lng - startLocation.lng);
      const courierLocation = { lat, lng };

      this.setState({ courierLocation });
    }, 2000);
  };

  render() {
    const { startLocation, endLocation } = this.props;
    const { distance, courierLocation } = this.state;

    const pathCoordinates = [
      startLocation,
      courierLocation || startLocation, // Use courier location if available, otherwise start location
      endLocation,
    ];

    return (
      <div>
        <Map google={this.props.google} zoom={14} initialCenter={startLocation}>
          <Marker position={startLocation} label="Start" />
          <Marker position={endLocation} label="End" />
          {courierLocation && (
            <Marker position={courierLocation} label="Courier" />
          )}
          <Polyline
            path={pathCoordinates}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
          />
        </Map>
        {distance && <p>Distance: {distance}</p>}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  // apiKey: "AIzaSyDhTs7c-6s1Sm_UsfUHi46N55CKCikh61I",
})(MapContainer);
