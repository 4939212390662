import React from "react";
import OrderChart from "../OrderChart/OrderChart";
import Statistic from "../Statistic/Statistic";
import styled from "./Dashboard.module.scss";

const Dashboard = () => {
  return (
    <div>
      <summary className={styled.login_user}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h2>Welcome back, Jane ✋</h2>
              <p>This is what’s happening with your business so far.</p>
            </div>
          </div>
        </div>
      </summary>
      <Statistic />
      <OrderChart />
      {/* <Link to="/register"> REGISTER</Link> */}
    </div>
  );
};

export default Dashboard;
