import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Dispatch = createApi({
  reducerPath: "Dispatch",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://app.peagle.app",
  }),
  tagTypes: ["Dispatch"],
  endpoints: (builder) => ({
    GET_ALL_UNASSIGNED: builder.query({
      query: (payload) => ({
        url: "/api/dispatch/unAssignedOrders",
        method: "GET",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["dispatch"],
    }),
    GETALLASSIGNED: builder.query({
      query: (payload) => ({
        url: `/api/dispatch`,
        method: "GET",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),

      invalidatesTags: ["dispatch"],
    }),
  }),
});

export const { useGET_ALL_UNASSIGNEDQuery, useGETALLASSIGNEDQuery } = Dispatch;
