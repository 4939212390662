import React from "react";
import OtpInput from "react18-input-otp";
import styles from "./register.module.scss";
import Logo from "../../assets/Peagle PP.jpg";
import clsx from "clsx";
import { useState } from "react";
import Spinner from "../Spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
const StepTwo = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState({
    veryfy_code: "",
  });
  const [loading, setIsloading] = useState(false);
  const handleChange = (code) => {
    setCode({ veryfy_code: code });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
    localStorage.setItem("Otp", JSON.stringify(code));

    if (code.veryfy_code) {
      navigate("/registerthree");
    } else {
      navigate("/register");
    }
    const a = localStorage.getItem("Otp");
    const b = JSON.parse(localStorage.getItem("user"));
    var newForm = {
      email: b[0].email,
      veryfy_code: code.veryfy_code,
    };
    axios
      .post("https://app.peagle.app/auth/verify", newForm, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        localStorage.setItem("token", res.data.data);
        console.log(res);
        if (res.status == 201) {
          navigate("/registerthree");
        }
      });
  };

  return (
    <section className={styles.register}>
      <div className="container-fluid">
        <div className="row">
          <div className={clsx("col-xl-5 offset-xl-1 g-0")}>
            <div className={styles.left_side}>
              <div className={styles.head}>
                <img src={Logo} alt="" />
                <h2>Peagle</h2>
              </div>
              <div className={styles.title}>
                <h2>Check Your Email</h2>
                <p>
                  Please enter the verification code that has been sent to your
                  email.{" "}
                </p>
              </div>

              <div>
                <form>
                  <OtpInput
                    value={code.veryfy_code}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      borderRadius: "8px",
                      border: "1px solid  #EAECF0",
                      width: "54px",
                      height: "54px",
                      fontSize: "16px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                      margin: "20px 0px",
                    }}
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none",
                    }}
                  />

                  <button
                    onClick={HandleSubmit}
                    className={
                      code.veryfy_code.length != 6
                        ? styles.otp_pending
                        : styles.otp_success
                    }
                  >
                    Continue
                    {loading ? (
                      <Spinner className={"text-light spinner-border-sm"} />
                    ) : (
                      ""
                    )}
                  </button>
                  <p className={styles.pending_time}>
                    Didn’t get a code? Resend in 3:00
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className={clsx("col-xl-6 g-0")}>
            <div className={styles.bg_color}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepTwo;
