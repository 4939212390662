import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
  curriers: [],
  loading: false,
  error: null,
};
export const deleteCourier = createAsyncThunk(
  "couriers/deleteCurier",
  async (id) => {
    const response = await fetch(
      `https://app.peagle.app/api/courier/destroy/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }
    );
    const data = await response.json();
    return data;
  }
);

export const fetchCourier = createAsyncThunk(
  "couriers/fetchCurrier",
  async () => {
    const response = await fetch("https://app.peagle.app/api/courier/all");
    const data = await response.json();
    console.log(data);
    return data;
  }
);

const PartnerSlice = createSlice({
  name: "couriers",
  initialState,

  extraReducers: {
    [fetchCourier.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchCourier.fulfilled]: (state, action) => {
      state.partners.push(action.payload);
      state.status = "succeeded";
    },
    [fetchCourier.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteCourier.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteCourier.fulfilled]: (state, action) => {
      return [];
    },
  },
});
const { reducer, actions } = PartnerSlice;
export default PartnerSlice.reducer;
