import React from "react";
import styled from "./SettingBanner.module.scss";
import { AiOutlineSearch } from "react-icons/ai";

const SettingBanner = () => {
  return (
    <div>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-9">
                <div className={styled.left_side}>
                  <h2>Settings</h2>
                  <p>Manage your business here.</p>
                </div>
              </div>
              <div className="col-xl-3">
                <div className={styled.search_order}>
                  <input type="search" placeholder="Search..." />
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingBanner;
