import React from "react";
import OtpInput from "react18-input-otp";
import styles from "./register.module.scss";
import Logo from "../../assets/Peagle PP.jpg";
import clsx from "clsx";
import { useState } from "react";
import Spinner from "../Spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
const StepThree = () => {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState({
    type: "",
  });
  const [checked, setChecked] = useState(false);
  const [loading, setIsloading] = useState(false);
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod({ type: e.target.value });
    checked ? setChecked(false) : setChecked(true);
  };
  const HandleSubmit = () => {
    setIsloading(true);
    setTimeout(() => {
      setIsloading(false);
    }, 1000);

    localStorage.setItem("type", JSON.stringify(paymentMethod));
    if (paymentMethod.type) {
      navigate("/registerfour");
    }
  };

  return (
    <section className={styles.register}>
      <div className="container-fluid">
        <div className="row">
          <div className={clsx("col-xl-5 offset-xl-1 g-0")}>
            <div className={styles.left_side}>
              <div className={styles.head}>
                <img src={Logo} alt="" />
                <h2>Peagle</h2>
              </div>
              <div className={styles.title}>
                <h2>Select your Business type</h2>
                <p>What do you do?</p>
              </div>

              <div
                style={{
                  dflex: "flex",
                  width: "100%",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className={`flex items-center pl-4 my-3 border ${
                    paymentMethod.type === "1"
                      ? "bg-blue-200"
                      : "border-gray-200"
                  } rounded dark:border-gray-700 px-3`}
                >
                  <label
                    htmlFor="bordered-radio-1"
                    className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Pickup and Delivey
                    <p className={`my-1 ${styles.selected_typeof_buiosness}`}>
                      Includes up to 10 users{" "}
                    </p>
                  </label>
                  <input
                    checked={paymentMethod.type === "1"}
                    onChange={handlePaymentMethodChange}
                    id="bordered-radio-1"
                    type="radio"
                    value="1"
                    name="payment_method"
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ${
                      paymentMethod.type === "1" ? "focus:bg-blue-200" : ""
                    }`}
                  />
                </div>

                <div
                  className={`flex items-center pl-4 border ${
                    paymentMethod.type === "2"
                      ? "bg-blue-200"
                      : "border-gray-200"
                  } rounded dark:border-gray-700 px-3`}
                >
                  <label
                    htmlFor="Debit/Credit card"
                    className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Only Delivery
                    <p className={`my-1 ${styles.selected_typeof_buiosness}`}>
                      Includes up to 10 users{" "}
                    </p>
                  </label>
                  <input
                    onChange={handlePaymentMethodChange}
                    checked={paymentMethod.type === "2"}
                    id="bordered-radio-2"
                    type="radio"
                    value="2"
                    name="payment_method"
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ${
                      paymentMethod.type === "2" ? "focus:bg-blue-200" : ""
                    }`}
                  />
                </div>
                <button
                  onClick={HandleSubmit}
                  className={
                    paymentMethod.type === ""
                      ? styles.otp_pending
                      : styles.otp_success
                  }
                >
                  Continue
                  {loading ? (
                    <Spinner className={"text-light spinner-border-sm"} />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className={clsx("col-xl-6 g-0")}>
            <div className={styles.bg_color}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepThree;
