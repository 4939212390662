import React from "react";
import SettingBanner from "../../BannerComonent/SettingBanner/SettingBanner";
import SettingTabs from "../../Tabs/SettingTabs/SettingTabs";

const Settings = () => {
  return (
    <div>
      <SettingBanner />
      <SettingTabs />
    </div>
  );
};

export default Settings;
