import React from "react";
import Navbar from "./Navbar/Navbar";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <div>
      {location.pathname !== "/login" && location.pathname !== "/register" && (
        <Navbar />
      )}

      <main>{children}</main>
    </div>
  );
};

export default Layout;
