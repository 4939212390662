import BannerComponent from "../../BannerComonent/BannerComponent";
import styled from "./EditCourier.module.scss";
import React, { useState } from "react";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
// import toast, { Toaster } from "react-hot-toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const EditCourier = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setIsloading] = useState(false);
  const [form, setForm] = useState({
    fullname: "",
    email: "",
    phone: "",
    plate_number: "",
    password: "",
    password_confirmation: "",
    transport_type_id: "",
  });

  useEffect(() => {
    axios
      .get(`https://app.peagle.app/api/courier/update/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setForm(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleChnage = (e) => {
    const value = e.target.value;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  };
  const HandleSubmit = (e) => {
    if (form.phone.length < 5) {
      toast.error("Phone number is not valid!");
      return;
    }
    if (form.password.length < 8) {
      toast.error("Password  is not valid!");
      return;
    }
    if (form.password !== form.password_confirmation) {
      toast.error("Password doesn't match!");
      return;
    }
    if (form.transport_type_id === "") {
      toast.error("Transport type is not valid!");
      return;
    }
    if (form.plate_number < 6) {
      toast.error("Plate number is not valid!");
      return;
    }
    setIsloading(true);
    toast.promise(
      axios
        .patch(
          `https://app.peagle.app/api/courier/update/${id}?`,
          {
            form,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              navigate("/couriers");
            }, 1000);
          }
        })
        .finally(() => {
          setIsloading(false);
        }),
      // .catch((err) => {
      //   // console.log(err.response.data.message);
      //   console.log(err.response.data.errors.message.plate_number[0]);
      //   var error = err.response.data.errors.message.plate_number[0];
      //   return err;
      // }),
      {
        pending: "Creating Currier...",
        success: "Creating Currier successfully",
        error: (err) =>
          // err.response.data.errors.message ??
          "Something is wrong, please try again",
      }
    );
    // console.log(form);
    setForm({
      fullname: "",
      email: "",
      phone: "",
      plate_number: "",
      password: "",
      password_confirmation: "",
      transport_type_id: "",
    });
  };

  return (
    <div>
      <BannerComponent
        HeadingName="Edit Currier"
        HeadingText="Back to Currier list"
      >
        <div className={styled.btns_group}>
          <button
            onClick={() => window.history.back()}
            className={styled.cancel_btn}
          >
            Cancel
          </button>

          <div onClick={() => HandleSubmit()}>
            {loading ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button className={styled.save_btn}>Save</button>
            )}
          </div>
        </div>
      </BannerComponent>

      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-7">
            <div className={styled.forms_elements}>
              <div className={styled.driver_details}>
                <h2>Driver details</h2>
                <p>Placeholder</p>
              </div>
              <form>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Name</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    onChange={handleChnage}
                    placeholder="Enter the name"
                    value={form.fullname}
                    required
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Email address</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="marthe@friendly.com"
                      onChange={handleChnage}
                      value={form.email}
                      required
                    />
                    <MdEmail color="#667085" size={16} />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Phone number</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      minLength="5"
                      maxLength="200"
                      placeholder="6 00 00 00 00"
                      onChange={handleChnage}
                      value={form.phone}
                      required
                    />
                    <BsFillTelephoneFill color="#667085" size={16} />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Transport type</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <select
                      onChange={handleChnage}
                      value={form.transport_type_id}
                      name="transport_type_id"
                      id="transport_type_id"
                      required
                    >
                      <option disabled selected>
                        Choose
                      </option>
                      <option value="1">Car</option>
                      <option value="2">Motocycle</option>
                    </select>
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Plate Number</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="plate_number"
                    id="plate_number"
                    value={form.plate_number}
                    placeholder="Plate Number"
                    onChange={handleChnage}
                    required
                    autoComplete="username"
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Temporary password</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.password}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Enter password"
                      onChange={handleChnage}
                      value={form.password}
                      required
                      autoComplete="new-password"
                    />
                    <AiFillEye
                      className="showpassword"
                      color="#667085"
                      size={16}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name"> password Confirmation</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.password}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password_confirmation"
                      id="password_confirmation"
                      autoComplete="new-password"
                      placeholder="Enter password"
                      onChange={handleChnage}
                      value={form.password_confirmation}
                      required
                    />
                    <AiFillEye
                      className="showpassword"
                      color="#667085"
                      size={16}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCourier;
