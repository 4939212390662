import React from "react";
import Order from "../../Order/Order";

const Orders = () => {
  return (
    <div>
      <Order />
    </div>
  );
};

export default Orders;
