import React from "react";

const Input = ({
  type,
  pleaceholder,
  onChange,
  onKeyDown,
  className,
  value,
  ...props
}) => {
  return (
    <input
      type={type}
      className={className}
      placeholder={pleaceholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      {...props}
    />
  );
};

export default Input;
