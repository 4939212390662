import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import styled from "./Partner.module.scss";
import PartnerTableList from "./PartnerTableList/PartnerTableList";
import { Link } from "react-router-dom";
const Partners = () => {
  return (
    <section className={styled.partners}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-9 g-2">
            <h2>Partners</h2>
            <p>Track, manage and forecast your orders.</p>
          </div>
          <div className="col-xl-3 g-2">
            <div className="add_partners">
              <button className={styled.add_button}>
                <Link to="/addpartner"> Add partner</Link>
                <AiOutlinePlus />
              </button>
            </div>
          </div>
        </div>
      </div>
      <PartnerTableList />
    </section>
  );
};

export default Partners;
