import React from "react";
import "./OrderCard.scss";
const OrderCard = ({
  icons,
  head,
  title,
  className,
  bgColor,
  color,
  BorderColor,
  ...props
}) => {
  return (
    <div {...props} className="col-xl-6 col-md-6 col-sm-12 g-2">
      <div className="order_card">
        <div
          style={{ backgroundColor: bgColor, borderColor: BorderColor }}
          className="card_icons"
        >
          <img style={{ color: color }} src={icons} alt="" />
        </div>
        <div className="card_detail">
          <h3>{head}</h3>
          <h5>{title}</h5>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
