import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Couriers = createApi({
  reducerPath: "Couriers",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://app.peagle.app",
  }),
  tagTypes: ["Couriers"],
  endpoints: (builder) => ({
    GET_ALL_COURIERS: builder.query({
      query: (payload) => ({
        url: "/api/courier/all",
        method: "GET",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token")
            ? localStorage.getItem("login_token")
            : "",
        },
      }),
      invalidatesTags: ["Couriers"],
    }),
    GET_COURIER_BY_ID: builder.query({
      query: (payload) => ({
        url: `/api/couriers/${payload}`,
        method: "GET",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Couriers"],
    }),
    CREATE_COURIER: builder.mutation({
      query: (payload) => ({
        url: "/api/couriers",
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Couriers"],
    }),

    UPDATE_COURIER: builder.mutation({
      query: (payload) => ({
        url: `/api/couriers/${payload.id}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Couriers"],
    }),
    DELETE_COURIER: builder.mutation({
      query: (payload) => ({
        url: `/api/couriers/${payload}`,
        method: "DELETE",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Couriers"],
    }),
  }),
});

export const {
  useGET_ALL_COURIERSQuery,
  useGET_COURIER_BY_IDQuery,
  useCREATE_COURIERMutation,
  useUPDATE_COURIERMutation,
  useDELETE_COURIERMutation,
} = Couriers;
