import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const Orders = createApi({
  reducerPath: "Orders",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://app.peagle.app",
  }),
  tagTypes: ["Orders"],
  endpoints: (builder) => ({
    // deletePartner: builder.mutation({
    //   query: (id) => ({
    //     url: `/api/partner/destroy/${id}`,
    //     method: "DELETE",
    //     // credentials: "include",
    //   }),
    //   invalidatesTags: ["Partners"],
    // }),

    GET_ALL_ORDERS: builder.query({
      query: (payload) => ({
        url: "/api/order/all",
        method: "GET",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token")
            ? localStorage.getItem("login_token")
            : "",
        },
      }),
      invalidatesTags: ["orders"],
    }),
    GET_ORDER: builder.mutation({
      query: (id) => ({
        url: `/api/order/update/${id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token")
            ? localStorage.getItem("login_token")
            : "",
        },
      }),
      providesTags: (result, error, arg) => [{ type: "Post", id: arg }],
      invalidatesTags: ["Orders"],
    }),
    // AddPartner: builder.mutation({
    //   query: (payload) => ({
    //     url: "/albums",
    //     method: "POST",
    //     body: payload,
    //     Headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }),
    //   invalidatesTags: ["Partners"],
    // }),
    // UpdatePartner: builder.mutation({
    //   query: (payload) => ({
    //     url: "/albums",
    //     method: "PUT",
    //     body: payload,
    //     Headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }),
    //   invalidatesTags: ["Partners"],
    // }),
  }),
});

export const { useGET_ALL_ORDERSQuery, useGET_ORDERMutation } = Orders;
