import axios from "axios";
const HTTP = axios.create({
  baseURL: "https://app.peagle.app",
});

export const GetCurrier = HTTP.get("/api/courier/all", {
  headers: {
    Authorization: localStorage.getItem("login_token"),
    Accept: "application/json",
  },
}).then((res) => {
  return res;
});
export const GetPartners = HTTP.get("/api/partner/all", {
  headers: {
    Authorization: localStorage.getItem("login_token"),
    Accept: "application/json",
  },
}).then((res) => res);
