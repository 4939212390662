import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const Partners = createApi({
  reducerPath: "Partners",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://app.peagle.app",
  }),
  tagTypes: ["Partners"],
  endpoints: (builder) => ({
    deletePartner: builder.mutation({
      query: (id) => ({
        url: `/api/partner/destroy/${id}`,
        method: "DELETE",
        // credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Partners"],
    }),

    getAllPartners: builder.query({
      query: (payload) => ({
        url: "/api/partner/all",
        method: "GET",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Partners"],
    }),
    AddPartner: builder.mutation({
      query: (payload) => ({
        url: "/albums",
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Partners"],
    }),
    UpdatePartner: builder.mutation({
      query: (payload) => ({
        url: "/albums",
        method: "PUT",
        body: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("login_token"),
        },
      }),
      invalidatesTags: ["Partners"],
    }),
  }),
});

export const {
  useGetAllPartnersQuery,
  useDeletePartnerMutation,
  useAddPartnerMutation,
  useUpdatePartnerMutation,
} = Partners;
