import { ReactComponent as WeatherIcon } from "../../../assets/icons/weatherIcon.svg";
import { ReactComponent as RainyIcon } from "../../../assets/icons/rainIcon.svg";
import { ReactComponent as SunnyIcon } from "../../../assets/icons/sunIcon.svg";
import { ReactComponent as FoggyIcon } from "../../../assets/icons/fogIcon.svg";
import { ReactComponent as SnowyIcon } from "../../../assets/icons/snowIcon.svg";
import { ReactComponent as TempAboveIcon } from "../../../assets/icons/temperatureAboveIcon.svg";
import { ReactComponent as TempBelowIcon } from "../../../assets/icons/temperatureBelowIcon.svg";
import { ReactComponent as TempBetweenIcon } from "../../../assets/icons/temperatureIcon.svg";

import { ReactComponent as WeightIcon } from "../../../assets/icons/weightIcon.svg";
import { ReactComponent as WeigthAbove } from "../../../assets/icons/weightAboveIcon.svg";
import { ReactComponent as WeigthBelow } from "../../../assets/icons/weightBelow.svg";
import { ReactComponent as WeigthBetween } from "../../../assets/icons/weightBetween.svg";

import { ReactComponent as PiecesIcon } from "../../../assets/icons/piecesIcon.svg";
import { ReactComponent as PiecesAboveIcon } from "../../../assets/icons/piecesAbove.svg";
import { ReactComponent as PiecesBelowIcon } from "../../../assets/icons/piecesBelow.svg";
import { ReactComponent as PiecesBetweenIcon } from "../../../assets/icons/piecesBetween.svg";

import { ReactComponent as TransportType } from "../../../assets/icons/transportTypeIcon.svg";
import { ReactComponent as CarIcon } from "../../../assets/icons/carIcon.svg";
import { ReactComponent as TruckIcon } from "../../../assets/icons/truckIcon.svg";
import { ReactComponent as BikeIcon } from "../../../assets/icons/bikeIcon.svg";
import { ReactComponent as MotorcycleIcon } from "../../../assets/icons/motoIcon.svg";
import { ReactComponent as OnFootIcon } from "../../../assets/icons/onFootIcon.svg";

import { ReactComponent as TimeIcon } from "../../../assets/icons/clockIcon.svg";

import { ReactComponent as DistanceIcon } from "../../../assets/icons/distanceIcon.svg";

import { ReactComponent as DeliveryIcon } from "../../../assets/icons/deliveryStatus.svg";

import { ReactComponent as TrafficLightIcon } from "../../../assets/icons/trafficLights.svg";

import { ReactComponent as BellIcon } from "../../../assets/icons/bellIcon.svg";

import { ReactComponent as WarningIcon } from "../../../assets/icons/warnIcon.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/crossIcon.svg";
import { ReactComponent as NotEqualIcon } from "../../../assets/icons/notEqualIcon.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/linkIcon.svg";
import { ReactComponent as ReAssign } from "../../../assets/icons/reAssignIcon.svg";
import { ReactComponent as AssignIcon } from "../../../assets/icons/assignIcon.svg";
import { ReactComponent as ReDropIcon } from "../../../assets/icons/reDropIcon.svg";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIcon.svg";
import { ReactComponent as TickIcon } from "../../../assets/icons/tickIcon.svg";

export const SUBCATEGORY_ENUM = {
  INFO: "info",
  JOIN_INPUT: "join_input",
  BETWEEN_INPUT: "between_input",
  TEXTAREA: "textarea",
  VALUE_INDICATOR: "value_indicator",
};

export const CONDITIONS_DATA = [
  {
    label: "Weather",
    value: "weather",
    icon: <WeatherIcon />,
    subCategories: [
      {
        label: "Rainy",
        value: "rainy",
        icon: <RainyIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if it's raining or not`,
      },
      {
        label: "Snowing",
        value: "snowing",
        icon: <SnowyIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if it's snowing or not`,
      },
      {
        label: "Sunny",
        value: "sunny",
        icon: <SunnyIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if it's sunny or not`,
      },
      {
        label: "Foggy",
        value: "foggy",
        icon: <FoggyIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if it's foggy or not`,
      },
      {
        label: "Temp. above",
        value: "tempAbove",
        icon: <TempAboveIcon />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        valueType: "°C",
        values: [""],
      },
      {
        label: "Temp. below",
        value: "tempBelow",
        icon: <TempBelowIcon />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        valueType: "°C",
        values: [""],
      },
      {
        label: "Temp. between",
        value: "tempBetween",
        icon: <TempBetweenIcon />,
        type: SUBCATEGORY_ENUM.BETWEEN_INPUT,
        info: `Checks if it's raining or not`,
        valueType: "°C",
        values: ["", ""],
      },
    ],
  },
  {
    label: "Weight",
    value: "weight",
    icon: <WeightIcon />,
    subCategories: [
      {
        label: "Weight Above",
        value: "weightAbove",
        icon: <WeigthAbove />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        valueType: "KG",
        values: [""],
      },
      {
        label: "Weight below",
        value: "weightBelow",
        icon: <WeigthBelow />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        valueType: "KG",
        values: [""],
      },
      {
        label: "Weight between",
        value: "weightBetween",
        icon: <WeigthBetween />,
        type: SUBCATEGORY_ENUM.BETWEEN_INPUT,
        valueType: "KG",
        values: ["", ""],
      },
    ],
  },
  {
    label: "Pieces",
    value: "pieces",
    icon: <PiecesIcon />,
    subCategories: [
      {
        label: "Pieces Above",
        value: "piecesAbove",
        icon: <PiecesAboveIcon />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        valueType: "QTY",
        values: [""],
      },
      {
        label: "Pieces below",
        value: "piecesBelow",
        icon: <PiecesBelowIcon />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        valueType: "QTY",
        values: [""],
      },
      {
        label: "Pieces between",
        value: "piecesBetween",
        icon: <PiecesBetweenIcon />,
        type: SUBCATEGORY_ENUM.BETWEEN_INPUT,
        valueType: "QTY",
        values: ["", ""],
      },
    ],
  },
  {
    label: "Transport Type",
    value: "transportType",
    icon: <TransportType />,
    subCategories: [
      {
        label: "Car",
        value: "car",
        icon: <CarIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: "Refers to the type of vehicle assigned to a driver for making deliveries",
      },
      {
        label: "Truck",
        value: "truck",
        icon: <TruckIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: "Refers to the type of vehicle assigned to a driver for making deliveries",
      },
      {
        label: "Bike",
        value: "bike",
        icon: <BikeIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: "Refers to the type of vehicle assigned to a driver for making deliveries",
      },
      {
        label: "Motorcycle",
        value: "motorcycle",
        icon: <MotorcycleIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: "Refers to the type of vehicle assigned to a driver for making deliveries",
      },
      {
        label: "On-foot",
        value: "onFoot",
        icon: <OnFootIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: "Means that the delivery will be made by a pedestrian",
      },
    ],
  },
  {
    label: "Timeframe",
    value: "timeframe",
    icon: <TimeIcon />,
    subCategories: [
      {
        label: "ASAP",
        value: "asap",
        icon: <TimeIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Prioritizes an order for immediate delivery`,
      },
      {
        label: "End of the day",
        value: "endOfTheDay",
        icon: <TimeIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Prioritizes an order for an end of the day delivery`,
      },
      {
        label: "Time-sensitive",
        value: "timeSensitive",
        icon: <TimeIcon />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        info: `Checks if an order is time-sensitive and needs to be delivered within a specific timeframe`,
        valueType: "HRS",
        values: [""],
      },
      {
        label: "Time Between",
        value: "timeBetween",
        icon: <TimeIcon />,
        type: SUBCATEGORY_ENUM.BETWEEN_INPUT,
        info: `Checks if the current time falls within a specified time range`,
        valueType: "time",
        values: ["", ""],
      },
    ],
  },
  {
    label: "Distance",
    value: "distance",
    icon: <DistanceIcon />,
    subCategories: [
      {
        label: "Above Distance",
        value: "aboveDistance",
        icon: <DistanceIcon />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        info: `Checks if the distance is above a certain threshold`,
        valueType: "KM",
        values: [""],
      },
      {
        label: "Below Distance",
        value: "belowDistance",
        icon: <DistanceIcon />,
        type: SUBCATEGORY_ENUM.VALUE_INDICATOR,
        info: `Checks if the distance is below a certain threshold`,
        valueType: "KM",
        values: [""],
      },
      {
        label: "Distance Between",
        value: "distanceBetween",
        icon: <DistanceIcon />,
        type: SUBCATEGORY_ENUM.BETWEEN_INPUT,
        info: `Checks if the distance falls within a specified range`,
        valueType: "KM",
        values: ["", ""],
      },
    ],
  },
  {
    label: "Delivery Status",
    value: "deliveryStatus",
    icon: <DeliveryIcon />,
    subCategories: [
      {
        label: "Unassigned",
        value: "unassigned",
        icon: <DeliveryIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks whether an order is unassigned or not`,
      },
      {
        label: "Started",
        value: "started",
        icon: <DeliveryIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks whether an order is started or not`,
      },
      {
        label: "Picked-up",
        value: "pickedUp",
        icon: <DeliveryIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks whether an order is picked-up or not`,
      },
      {
        label: "On the way",
        value: "onTheWay",
        icon: <DeliveryIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks whether an order is on the way or not`,
      },
      {
        label: "Delivered",
        value: "delivered",
        icon: <DeliveryIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: "Checks whether an order is delivered or not",
      },
      {
        label: "Failed",
        value: "failed",
        icon: <DeliveryIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks whether an order is failed or not`,
      },
    ],
  },
  {
    label: "Partner",
    value: "partner",
    icon: <WeatherIcon />,
    subCategories: [
      {
        label: "Papa John's",
        value: "papaJohns",
        icon: <WeatherIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
      },
    ],
  },
  {
    label: "Traffic",
    value: "traffic",
    icon: <TrafficLightIcon />,
    subCategories: [
      {
        label: "Light traffic",
        value: "lightTraffic",
        icon: <TrafficLightIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if traffic is light`,
      },
      {
        label: "Stop-and-go traffic",
        value: "stopAndGoTraffic",
        icon: <TrafficLightIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if traffic is stop-and-go`,
      },
      {
        label: "Heavy traffic",
        value: "heavyTraffic",
        icon: <TrafficLightIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if traffic is heavy`,
      },
      {
        label: "Accidents/Incidents",
        value: "accidentsIncidents",
        icon: <TrafficLightIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if there are accidents/incidents`,
      },
    ],
  },
  {
    label: "Notification",
    value: "noitification",
    icon: <BellIcon />,
    subCategories: [
      {
        label: "Notify Customer",
        value: "notifyCustomer",
        icon: <BellIcon />,
        type: SUBCATEGORY_ENUM.TEXTAREA,
        info: `Notifies the customer with specified message`,
        values: [""],
      },
      {
        label: "Notify Courier",
        value: "notifyCourier",
        icon: <BellIcon />,
        type: SUBCATEGORY_ENUM.TEXTAREA,
        info: `Notifies the driver with specified message`,
        values: [""],
      },
    ],
  },
  {
    label: "Helper Nodes",
    value: "helperNodes",
    icon: <WarningIcon />,
    subCategories: [
      {
        label: "Is Priority",
        value: "isPriority",
        icon: <WarningIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Prioritizes a certain task or condition in the decision-making process.`,
      },
      {
        label: "Exception",
        value: "exception",
        icon: <CrossIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Used to handle tasks or conditions that are outside of the normal flow`,
      },
      {
        label: "Do not",
        value: "doNot",
        icon: <NotEqualIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Excludes certain conditions from being considered`,
      },
      {
        label: "Join",
        value: "join",
        icon: <LinkIcon />,
        type: SUBCATEGORY_ENUM.JOIN_INPUT,
        info: `Combines the outputs of two nodes in parallel`,
        values: ["", ""],
      },
      {
        label: "Reassign",
        value: "reassign",
        icon: <ReAssign />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Assigns the order to a different driver`,
      },
      {
        label: "Redrop",
        value: "redrop",
        icon: <ReDropIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Redrops a failed delivery and makes it active again`,
      },
      {
        label: "Assign",
        value: "assign",
        icon: <AssignIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Assigns an order to a driver based on certain conditions`,
      },
      {
        label: "Is Late",
        value: "isLate",
        icon: <TimeIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: `Checks if an order is past its expected delivery time`,
      },
    ],
  },
  {
    label: "Starting Node",
    value: "startingNode",
    icon: <PlusIcon />,
    subCategories: [
      {
        label: "New Order",
        value: "newOrder",
        icon: <PlusIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: ` Represents the action of receiving a new order`,
      },
    ],
  },
  {
    label: "Ending Node",
    value: "endingNode",
    icon: <TickIcon />,
    subCategories: [
      {
        label: "Delivery Completed",
        value: "deliveryCompleted",
        icon: <TickIcon />,
        type: SUBCATEGORY_ENUM.INFO,
        info: ` Signals the successful completion of a delivery`,
      },
    ],
  },
];
