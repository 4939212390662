import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/logo.png";
import styled from "./Navbar.module.scss";
import { FiMessageCircle } from "react-icons/fi";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { AiFillSetting } from "react-icons/ai";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
const Navbars = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <>
      {" "}
      <nav className={styled.nav}>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-2">
              <div className={styled.logo}>
                {" "}
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-7">
              <ul className={styled.list}>
                <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" && styled.active}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/orders"
                    className={location.pathname === "/orders" && styled.active}
                  >
                    Orders
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dispatch"
                    className={
                      location.pathname === "/dispatch" && styled.active
                    }
                  >
                    Dispatch
                  </Link>
                </li>
                <li>
                  <Link
                    to="map"
                    className={location.pathname === "/map" && styled.active}
                  >
                    Map
                  </Link>
                </li>
                <li>
                  <Link
                    to="/couriers"
                    className={
                      location.pathname === "/couriers" && styled.active
                    }
                  >
                    Drivers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/conditions"
                    className={
                      location.pathname === "/conditions" && styled.active
                    }
                  >
                    Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/partners"
                    className={
                      location.pathname === "/partners" && styled.active
                    }
                  >
                    Partners
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-3">
              <ul className={styled.right_list}>
                <li>
                  <FiMessageCircle />
                </li>
                <li>
                  <HiOutlineBellAlert />
                </li>
                <li>
                  <Link to="setting">
                    <AiFillSetting />
                  </Link>
                </li>
                <li>
                  <div className={styled.avatar}>
                    <img
                      src="https://source.unsplash.com/1600x900/?nature,water"
                      alt=""
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <Navbar className={styled.mobile_menu} color="faded" light>
        <NavbarBrand href="/" className="me-auto">
          <div className={styled.logo}>
            {" "}
            <img src={logo} alt="" />
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink
                href="/"
                className={location.pathname === "/" && styled.active}
              >
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/orders"
                className={location.pathname === "/orders" && styled.active}
              >
                Orders
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/dispatch"
                className={location.pathname === "/dispatch" && styled.active}
              >
                dispatch
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/map"
                className={location.pathname === "/map" && styled.active}
              >
                Map
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/couriers"
                className={location.pathname === "/couriers" && styled.active}
              >
                Couriers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/conditions"
                className={location.pathname === "/conditions" && styled.active}
              >
                Conditions
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default Navbars;
