import React from "react";
import OtpInput from "react18-input-otp";
import styles from "./register.module.scss";
import Logo from "../../assets/Peagle PP.jpg";
import clsx from "clsx";
import { useState } from "react";
import Spinner from "../Spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const StepFour = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    county_code: "",
    company_name: "",
    country_id: "",
    city: "",
    person_name: "",
    phone: "",
  });
  const [loading, setIsloading] = useState(false);
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  console.log(form);
  const HandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
    const OTP = JSON.parse(localStorage.getItem("Otp"));
    const User = JSON.parse(localStorage.getItem("user"));
    const Token = localStorage.getItem("token");
    const Type = JSON.parse(localStorage.getItem("type"));
    const newFom = {
      email: User[0].email,
      veryfy_code: OTP.veryfy_code,
      token: Token,
      type: Type.type,
      county_code: form.country_id, //form.county_code,Olmalidir
      company_name: form.company_name,
      country_id: form.country_id,
      city: form.city,
      person_name: form.person_name,
      phone: form.phone,
    };
    console.log(newFom);
    axios
      .post("https://app.peagle.app/auth/company/register", newFom, {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          navigate("/login");
        } else {
          navigate("/register");
        }
      });
  };

  return (
    <section className={styles.register}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className={clsx("col-xl-5 offset-xl-1 g-0")}>
            <div className={styles.stepfourform}>
              <div className={styles.head}>
                <img src={Logo} alt="" />
                <h2>Peagle</h2>
              </div>
              <div className={styles.title}>
                <h2>More details</h2>
                <p>
                  Please give us more information to make things work better.
                </p>
              </div>
              <form className={styles.step_four_forms}>
                <div className={styles.country_form}>
                  <label htmlFor="country">Country</label>
                  <select
                    onChange={(e) => handleChange(e)}
                    value={form.country_id}
                    name="country_id"
                    id="country_id"
                  >
                    <option disabled value="">
                      Select Country
                    </option>
                    <option value={1}>Azerbaijan</option>
                    <option value={2}>Turkey</option>
                    <option value={3}>India</option>
                    <option value={4}>China</option>
                    <option value={5}>USA</option>
                    <option value={6}>UK</option>
                  </select>
                </div>
                <div className={styles.select_city}>
                  <label htmlFor="city">City</label>
                  <select
                    onChange={(e) => handleChange(e)}
                    value={form.city}
                    name="city"
                    id="city"
                  >
                    <option disabled value="">
                      Select City
                    </option>
                    <option value="baku">Baku</option>
                    <option value="istanbul">Istanbul</option>
                    <option value="dehlo">Delhi</option>
                    <option value="beijing">Beijing</option>
                    <option value="new_york">New York</option>
                    <option value="london">London</option>
                  </select>
                </div>
                <div className={styles.company_name_filed}>
                  <label htmlFor="company_name">Company Name</label>
                  <input
                    onChange={(e) => handleChange(e)}
                    value={form.company_name}
                    name="company_name"
                    type="text"
                    placeholder="Enter your company name"
                    required
                  />
                </div>
                <div className={styles.contact_person_name_filed}>
                  <label htmlFor="contact_person_name">
                    Contact Person Name
                  </label>
                  <input
                    onChange={handleChange}
                    value={form.person_name}
                    name="person_name"
                    type="text"
                    required
                    placeholder="Enter your contact person name"
                  />
                </div>
                <div className={styles.phone_fileds}>
                  <label htmlFor="phone_number">Phone Number</label>
                  <div className={styles.fileds_of_phones}>
                    <select
                      onChange={(e) => handleChange(e)}
                      value={form.county_code}
                      name="county_code"
                      id="county_code"
                    >
                      <option value="0">+(90)</option>
                      <option value="1">+(994)</option>
                      <option value="2">+(123)</option>
                      <option value="3">+(23)</option>
                      <option value="4">+(1)</option>
                    </select>
                    <input
                      onChange={handleChange}
                      value={form.phone}
                      name="phone"
                      type="text"
                      required
                      placeholder="Enter your phone number"
                      maxLength={12}
                    />
                  </div>
                </div>
                <button
                  onClick={HandleSubmit}
                  className={
                    loading === "" ? styles.otp_pending : styles.otp_success
                  }
                >
                  Continue
                  {loading ? (
                    <Spinner className={"text-light spinner-border-sm"} />
                  ) : (
                    ""
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className={clsx("col-xl-6 g-0")}>
            <div className={styles.bg_color}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepFour;
