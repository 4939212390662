import React, { useState } from "react";
import BannerComponent from "../../BannerComonent/BannerComponent";
import styled from "./AddPartner.module.scss";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { MultiSelect } from "primereact/multiselect";
import { AiFillEye } from "react-icons/ai";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AddPartner = () => {
  const Navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setIsLoading] = useState(false);
  const [courrier, setCourrier] = useState([]);
  const newCouriers = [];
  courrier.map((item) => {
    newCouriers.push({ name: item.fullname, value: item.id });
  });
  useEffect(() => {
    axios.get("https://app.peagle.app/api/courier/all").then((res) => {
      setCourrier(res.data.data);
    });
  }, []);
  const [form, setForm] = useState({
    file: null,
    name: "",
    person: "",
    email: "",
    phone: "",
    temporary_password: "",
  });
  const handleItemChange = (event, index) => {
    const value = event.target.value;
    setForm({
      ...form,
      [event.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    if (
      form.name === "" ||
      form.person === "" ||
      form.email === "" ||
      form.phone === "" ||
      form.temporary_password === ""
    ) {
      toast.error("Please fill all fields");
      return;
    }
    setIsLoading(true);
    axios
      .post("https://app.peagle.app/api/partner/store", form, {
        headers: {
          Authorization: localStorage.getItem("login_token"),
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          Navigate("/partners");
          window.location.reload();
          toast.success("Partner added successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <BannerComponent
        HeadingName="Add Partner"
        HeadingText="Back to Partner list"
      >
        {" "}
        <div className={styled.btns_group}>
          <button
            onClick={() => window.history.back()}
            className={styled.cancel_btn}
          >
            Cancel
          </button>

          <div onClick={() => handleSubmit()}>
            {loading ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button className={styled.save_btn}>Save</button>
            )}
          </div>
        </div>
      </BannerComponent>
      <div className="container">
        <div className="row  justify-content-center">
          <div className="col-xl-6">
            <div className={styled.forms_elements}>
              <div className={styled.driver_details}>
                <h2>Partner details</h2>
                <p>Placeholder</p>
              </div>
              <form action="">
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Company Logo</label>
                    <p>Pleaceholder</p>
                  </div>

                  <div style={{ width: "62%" }}>
                    <div className="flex items-center justify-end w-full">
                      <label
                        for="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-56 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-3 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-4 h-4 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-blue-500 dark:text-gray-400">
                            <span className="font-300 text-gray-400">
                              Drop your files here, or
                            </span>{" "}
                            browse
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF (MAX. 800x400px)
                          </p>
                        </div>
                        <input
                          onChange={handleItemChange}
                          value={form.file}
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          name="file"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Company Name</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter the name"
                    onChange={handleItemChange}
                    value={form.name}
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Contact Person</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="person"
                    id="name"
                    placeholder="Enter the name"
                    onChange={handleItemChange}
                    value={form.person}
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Email address</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="email"
                      name="email"
                      id="name"
                      placeholder="marthe@friendly.com"
                      onChange={handleItemChange}
                      value={form.email}
                    />
                    <MdEmail color="#667085" size={16} />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Phone number</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="tel"
                      name="phone"
                      id="name"
                      placeholder="6 00 00 00 00"
                      onChange={handleItemChange}
                      value={form.phone}
                    />
                    <BsFillTelephoneFill color="#667085" size={16} />
                  </div>
                </div>

                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Temporary password</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.password}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="temporary_password"
                      id="name"
                      placeholder="Enter password"
                      onChange={handleItemChange}
                      value={form.temporary_password}
                      required
                    />
                    <AiFillEye
                      className="showpassword"
                      color="#667085"
                      size={16}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-xl-6">
            <div className={styled.forms_elements}>
              <div className={styled.driver_details}>
                <h2>Partner details</h2>
                <p>Placeholder</p>
              </div>
              <form action="">
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Delivery type</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <select
                      name="delivery_type"
                      onChange={handleItemChange}
                      id=""
                    >
                      <option value="car">Car</option>
                      <option value="motocycle">Motocycle</option>
                      <option value="on_foot">On Foot</option>
                    </select>
                  </div>
                </div>

                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Attached couriers</label>
                    <p>Subtitle</p>
                  </div>
                  <div
                    style={{ width: "362px" }}
                    className="card flex justify-content-center"
                  >
                    <MultiSelect
                      value={selectedCities}
                      options={newCouriers}
                      onChange={handleOptionChange}
                      optionLabel="name"
                      placeholder="Select couriers"
                      className="w-full md:w-20rem"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddPartner;
