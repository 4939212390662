import React from "react";
import Deliverymap from "../../../lib/Deliverymap";

const Map = () => {
  const startLocation = { lat: 40.409264, lng: 49.867092 };
  const endLocation = { lat: 40.409264, lng: 49.977092 };
  return (
    <div>
      <Deliverymap startLocation={startLocation} endLocation={endLocation} />
    </div>
  );
};

export default Map;
