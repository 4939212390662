import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { Couriers } from "../features/api/Couriers";
import { Dispatch } from "../features/api/Dispatch";
import { Orders } from "../features/api/Order";
import { Partners } from "../features/api/Partner";
import CurierReducer from "../reducer/CurrierSlice";
import PartnerReducer from "../reducer/PartnerSlice";
export const store = configureStore({
  reducer: {
    curriers: CurierReducer,
    partners: PartnerReducer,
    [Partners.reducerPath]: Partners.reducer,
    [Orders.reducerPath]: Orders.reducer,
    [Dispatch.reducerPath]: Dispatch.reducer,
    [Couriers.reducerPath]: Couriers.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(Partners.middleware)
      .concat(Orders.middleware)
      .concat(Dispatch.middleware)
      .concat(Couriers.middleware),
});
setupListeners(store.dispatch);
