import React from "react";
import { DatePicker, Space } from "antd";
import { Radio } from "antd";

import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import DashboardStatistic from "../Atoms/DashboardStatistic/DashboardStatistic";
const Statistic = () => {
  const { RangePicker } = DatePicker;
  const onChange = (e) => {
    console.log(`radio checked:${e.target.value}`);
  };
  const Data = [
    {
      id: Math.random() * 1000,
      name: "Revenue",
      percentage: 14.55,

      total: 24.42,
    },
    {
      id: Math.random() * 1000,
      name: "Profit",
      percentage: 32.22,
      total: 24.42,
    },
    {
      id: Math.random() * 1000,
      name: "Expenses",
      percentage: 36.44,
      total: 24.42,
    },
  ];
  return (
    <section>
      <div className="container">
        <div className="row py-2  justify-content-between align-items-center">
          <div className="col-xl-6 col-md-6 col-sm-12 g-2">
            <div className="check_box_types">
              <Radio.Group onChange={onChange} defaultValue="a">
                <Radio.Button value="a">Today</Radio.Button>
                <Radio.Button value="b">last 7 days</Radio.Button>
                <Radio.Button value="c">This month</Radio.Button>
                <Radio.Button value="d">This year</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 g-2">
            <Space direction="vertical" size={12}>
              <RangePicker />
            </Space>
          </div>
        </div>
        <div className="row">
          {Data.length > 0 &&
            Data.map((element, index) => (
              <DashboardStatistic
                key={index}
                Price={element.total}
                Title={element.name}
                percentage={element.percentage}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Statistic;
