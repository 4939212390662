import React from "react";

const Button = ({ type, onClick, title, children, disabled, ...props }) => {
  return (
    <button type={type} disabled={disabled} {...props} onClick={onClick}>
      {children}
      {title}
    </button>
  );
};

export default Button;
