import React, { useState } from "react";
import BannerComponent from "../../BannerComonent/BannerComponent";
import styled from "../AddPartner/AddPartner.module.scss";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { MultiSelect } from "primereact/multiselect";
import { AiFillEye } from "react-icons/ai";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const PartnerEdit = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [courrier, setCourrier] = useState([]);
  const newCouriers = [];
  const [form, setForm] = useState({
    file: null,
    name: "",
    person: "",
    email: "",
    phone: "",
    temporary_password: "",
    delivery_type: "",
    courier_id: [],
  });
  courrier.map((item) => {
    newCouriers.push({ name: item.fullname, value: item.id });
  });
  useEffect(() => {
    axios.get("https://app.peagle.app/api/courier/all").then((res) => {
      setCourrier(res.data.data);
    });
    axios.get(`https://app.peagle.app/api/partner/update/${id}`).then((res) => {
      setForm({
        ...form,
        name: res.data.data.name,
        person: res.data.data.person,
        email: res.data.data.email,
        phone: res.data.data.phone,
        temporary_password: res.data.data.temporary_password,
        delivery_type: res.data.data.delivery_type,
        courier_id: res.data.data.courier_id,
      });
    });
  }, []);

  const handleItemChange = (event, index) => {
    const value = event.target.value;
    setForm({
      ...form,
      [event.target.name]: value,
    });
  };
  const handleOptionChange = (e) => {
    setSelectedCities(e.value);
  };

  const handleSubmit = (event) => {
    if (
      form.name === "" ||
      form.person === "" ||
      form.email === "" ||
      form.phone === "" ||
      form.temporary_password === "" ||
      form.delivery_type === "" ||
      form.courier_id === ""
    ) {
      toast.error("Please fill all fields");
      return;
    }
    form.courier_id = selectedCities;
    setIsLoading(true);
    axios
      .patch(`https://app.peagle.app/api/partner/update/${id}`, form, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
          Authorization: localStorage.getItem("login_token"),
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .then((res) => {
        if (res.status === 200) {
          Navigate("/partners");
          window.location.reload();
          toast.success("Partner added successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <BannerComponent
        HeadingName="Edit Partner"
        HeadingText="Back to Partner list"
      >
        {" "}
        <div className={styled.btns_group}>
          <button
            onClick={() => window.history.back()}
            className={styled.cancel_btn}
          >
            Cancel
          </button>

          <div onClick={() => handleSubmit(id)}>
            {loading ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button className={styled.save_btn}>Save</button>
            )}
          </div>
        </div>
      </BannerComponent>
      <div className="container">
        <div className="row  justify-content-center">
          <div className="col-xl-6">
            <div className={styled.forms_elements}>
              <div className={styled.driver_details}>
                <h2>Partner details</h2>
                <p>Placeholder</p>
              </div>
              <form action="">
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Company Logo</label>
                    <p>Pleaceholder</p>
                  </div>

                  <div style={{ width: "62%" }}>
                    <div className="flex items-center justify-end w-full">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-56 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                      >
                        <div className="flex flex-col items-center justify-center pt-3 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-4 h-4 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-blue-500 dark:text-gray-400">
                            <span className="font-300 text-gray-400">
                              Drop your files here, or
                            </span>{" "}
                            browse
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            SVG, PNG, JPG or GIF (MAX. 800x400px)
                          </p>
                        </div>
                        <input
                          onChange={handleItemChange}
                          value={form.file}
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          name="file"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Company Name</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter the name"
                    onChange={handleItemChange}
                    value={form.name}
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="person">Contact Person</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="person"
                    id="person"
                    placeholder="Enter the name"
                    onChange={handleItemChange}
                    value={form.person}
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="email">Email address</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="marthe@friendly.com"
                      onChange={handleItemChange}
                      value={form.email}
                    />
                    <MdEmail color="#667085" size={16} />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="phone">Phone number</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="6 00 00 00 00"
                      onChange={handleItemChange}
                      value={form.phone}
                    />
                    <BsFillTelephoneFill color="#667085" size={16} />
                  </div>
                </div>

                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="temporary_password">
                      Temporary password
                    </label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.password}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="temporary_password"
                      id="temporary_password"
                      placeholder="Enter password"
                      onChange={handleItemChange}
                      value={form.temporary_password}
                      required
                    />
                    <AiFillEye
                      className="showpassword"
                      color="#667085"
                      size={16}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-xl-6">
            <div className={styled.forms_elements}>
              <div className={styled.driver_details}>
                <h2>Partner details</h2>
                <p>Placeholder</p>
              </div>
              <form action="">
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="delivery_type">Delivery type</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <select
                      name="delivery_type"
                      onChange={handleItemChange}
                      id="delivery_type"
                      defaultValue={"car"}
                    >
                      <option value="car">Car</option>
                      <option value="motocycle">Motocycle</option>
                    </select>
                  </div>
                </div>

                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="courier">Attached couriers</label>
                    <p>Subtitle</p>
                  </div>
                  <div
                    style={{ width: "362px" }}
                    className="card flex justify-content-center"
                  >
                    <MultiSelect
                      value={selectedCities}
                      options={newCouriers}
                      onChange={handleOptionChange}
                      optionLabel="name"
                      placeholder="Select couriers"
                      className="w-full md:w-20rem"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PartnerEdit;
