import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import router from "./router";
import Layout from "../src/components/Layout/Layout";
import { initFlowbite } from "flowbite";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import StepTwo from "./components/Register/StepTwo";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const LoginToken = localStorage.getItem("login_token");
  useEffect(() => {
    initFlowbite();
    // LoginToken === null ? navigate("/login") : navigate("/");
  }, []);

  return LoginToken === null ? (
    <Routes>
      {" "}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/registertwo" element={<StepTwo />} />
    </Routes>
  ) : (
    <Layout>
      <Routes>
        {router.length > 0 &&
          router.map((element, index) => (
            <Route
              key={element.id}
              element={element.element}
              path={element.path}
            />
          ))}
      </Routes>
    </Layout>
  );
};
export default App;
