import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/searchIcon.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../assets/icons/rightArrow.svg";
import { ReactComponent as MacIcon } from "../../../../../assets/icons/macIcon.svg";

import useDebounce from "../../../../../hooks/useDebounce";
import cn from "classnames";
const SelectCondition = ({
  isSearchable,
  title,
  data,
  isMain,
  clickHandler,
  mouseOverhandler,
  isPosAbsolute,
  positions,
}) => {
  const [categories, setCategories] = useState(data);
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 500);
  console.log("POSITIONS", positions);
  const handleSearch = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    setCategories(data);
  }, [data]);
  useEffect(() => {
    if (debouncedValue.trim() !== "") {
      const _data = data.filter((el) =>
        el.label.toLowerCase().includes(debouncedValue)
      );
      setCategories(_data);
    } else {
      setCategories(data);
    }
  }, [debouncedValue]);
  return (
    <div
      className={cn(styles.element, !isMain && styles.sub)}
      style={{
        position: isPosAbsolute ? "absolute" : "static",
        top:
          isPosAbsolute && isMain
            ? positions.clientY - 100
            : positions.clientY - 80,
        left:
          isPosAbsolute && isMain
            ? positions.clientX + 60
            : positions.clientX + 464,
        zIndex: isPosAbsolute && 10000,
      }}
    >
      {isSearchable && (
        <div className={styles.searchWrapper}>
          <div className={styles.left}>
            <div className={styles.iconWrapper}>
              <SearchIcon />
            </div>
            <input onChange={handleSearch} type="text" placeholder="Search" />
          </div>
          <div className={styles.right}>
            <div className={styles.tag}>
              <MacIcon />
            </div>
          </div>
        </div>
      )}
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}
        {categories.map((el, idx) => (
          <div
            key={idx}
            className={styles.category}
            onMouseOver={isMain && (() => mouseOverhandler(el.value))}
            onClick={!isMain && (() => clickHandler(el.value))}
          >
            <div className={styles.left}>
              <div className={styles.categoryIcon}>{el.icon}</div>
              <p>{el.label}</p>
            </div>
            {isMain && (
              <div className={styles.right}>
                <div className="iconWrapper">
                  <RightArrowIcon />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCondition;
