import React, { useState } from "react";
import styled from "./PartnerTable.module.scss";
import { FaEdit } from "react-icons/fa";
import { RiListSettingsLine } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import clsx from "clsx";
import { confirmAlert } from "react-confirm-alert";
import {
  useDeletePartnerMutation,
  useGetAllPartnersQuery,
} from "../../../redux/features/api/Partner";
import { MdDelete } from "react-icons/md";
import Icon from "../../Icon/Icon";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
const PartnerTableList = () => {
  const Navigate = useNavigate();
  const [partnerDetail, setPartnerDetail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [deletePartner, { isLoading: isDeleting }] = useDeletePartnerMutation();
  const handleEdit = async (id, form) => {
    await axios
      .put(`https://app.peagle.app/api/courier/update/${id}`, {
        ...form,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Currier Updating Successfully!");
        }
      })
      .catch((err) => {
        toast.error("Currier Updating Failed!");
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const { data, isLoading, isError, isSuccess } = useGetAllPartnersQuery();

  const HandleDelete = async (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deletePartner(id);
            toast.success("Currier Deleted Successfully!");
            data && data?.data.filter((c) => c.id !== id);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.error("Currier Deleting Failed!");
          },
        },
      ],
    });
    // data && data?.data.filter((c) => c.id !== id);
  };
  const GetPartnerById = (id) => {
    setPartnerDetail(data && data.data.find((el) => el.id === id));
    handleOpen();
    setTimeout(() => {
      handleClose();
    }, 600);
  };
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: "200px", height: "200px" }}
          src="https://i.gifer.com/7Pzx.gif"
          alt="preload"
        />
      </div>
    );
  } else if (isError) {
    return <div> Not Found</div>;
  }
  return (
    <section>
      <div className="container py-5">
        <div className="row">
          <div className="col-xl-4 py-3">
            <div className={styled.search_order}>
              <input type="search" placeholder="Search..." />
              <AiOutlineSearch />
            </div>
          </div>
          <div className="col-xl-12">
            <table className="table">
              <thead>
                <tr className={styled.head_table}>
                  <th scope="col">
                    <input type="checkbox" />
                  </th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Contact person</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Delivery type</th>
                  <th scope="col">Couriers</th>
                  <th scope="col">
                    <RiListSettingsLine size={18} />
                  </th>
                </tr>
              </thead>
              <tbody className={styled.tbody}>
                {isSuccess &&
                  data &&
                  data.data.map((partner, index) => (
                    <tr key={partner.id}>
                      <th scope="row">
                        <input className={styled.check} type="checkbox" />
                      </th>
                      <td>
                        <div className={styled.data_img}>
                          <img
                            src="https://source.unsplash.com/1600x900/?nature,water"
                            alt=""
                          />
                          <div className="data_foter">
                            {" "}
                            <p>{partner.name}</p>
                            <h4>{partner.email || "---"}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className={styled.phone_number}>
                          {partner.person || "---"}
                        </p>
                      </td>
                      <td>
                        <p className={styled.phone_number}>
                          {partner.phone || "---"}
                        </p>
                      </td>
                      <td>
                        <button className={styled.express_status}>
                          Express
                        </button>
                      </td>
                      {partner.courier.length === 0 ? (
                        <td>
                          <p className={styled.phone_number}>
                            No assign Courier
                          </p>
                        </td>
                      ) : (
                        <td>
                          <p className={styled.curriers_name}>
                            {partner.courier[0].fullname}
                            <span
                              className={clsx(`badge text-bg-info text-white`)}
                            >
                              {partner.courier.length}
                            </span>
                          </p>
                        </td>
                      )}
                      <td>
                        <div
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          className={styled.edit}
                          onClick={() => GetPartnerById(partner.id)}
                        >
                          <FaEdit />
                        </div>
                      </td>
                      <td>
                        <div className={styled.setting}>
                          <div onClick={() => HandleDelete(partner.id)}>
                            {" "}
                            <MdDelete color="#F04438" size={22} />
                          </div>
                        </div>{" "}
                      </td>
                      <td>
                        <div className={styled.setting}>
                          <div onClick={() => Navigate(`${partner.id}`)}>
                            <Icon Name="edit" />
                          </div>
                        </div>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <section>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby={`staticBackdropLabel-${partnerDetail.id}`}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content custom_modal_width">
              <div className="modal-header">
                <h5
                  className="modal-title currier_modal"
                  id={`staticBackdropLabel- ${partnerDetail.id}`}
                >
                  Parnter Details
                </h5>
                <button
                  style={{
                    border: "1px solid #EAECF0",
                    borderRadius: "4px",
                    boxShadow: "none",
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row align-items-start g-4">
                    <div className="col-xl-6">
                      <div className={styled.left_side}>
                        <div className="left_side_img">
                          <img
                            src="https://source.unsplash.com/1600x900/?nature,water"
                            alt=""
                          />
                        </div>
                        <div className={styled.detail_name}>
                          <p>Company Name</p>
                          <h4>{partnerDetail.name}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className={styled.detail_name}>
                        <p>Contact Person</p>
                        <h4>{partnerDetail.person}</h4>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <div className={styled.detail_name}>
                        <p>Email Address</p>
                        <h4>{partnerDetail.email}</h4>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <div className={styled.detail_name}>
                        <p>Phone Number</p>
                        <h4>{partnerDetail.phone}</h4>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <div className={styled.detail_name}>
                        <p>Delivery Type</p>
                        <h3>Express</h3>
                      </div>
                    </div>
                    <div className="col-xl-12 col-md-6 col-sm-12">
                      <div className={styled.detail_name}>
                        <p>Attached Couriers</p>
                        <h5>
                          {partnerDetail.courier &&
                            partnerDetail.courier.map((courier) => (
                              <span key={courier.id}>{courier.fullname}</span>
                            ))}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn ${styled.delete_btn}`}
                  data-bs-dismiss="modal"
                  onClick={() => HandleDelete(partnerDetail.id)}
                >
                  Delete Partner
                </button>
                <button
                  // onClick={Navigate(`/partners/${partnerDetail.id}`)}
                  type="button"
                  className={`btn ${styled.edit_btn}`}
                >
                  Edit Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </section>
  );
};

export default PartnerTableList;
