import React from "react";
import { AiFillFilePdf, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styled from "./Order.module.scss";
import OrderList from "./OrderList/OrderList";
const Order = () => {
  const navigate = useNavigate();
  return (
    <section className={styled.order}>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-xl-9">
            <h3>Orders</h3>
            <p>Track, manage and forecast your orders.</p>
          </div>
          <div className="col-xl-3">
            <div className={styled.buttons}>
              <button className={styled.btn_export}>
                <AiFillFilePdf />
                Export as CSV
              </button>
              <button
                onClick={() => navigate("/addorder")}
                className={styled.add_button}
              >
                <AiOutlinePlus />
                New Order
              </button>
            </div>
          </div>
        </div>
        <OrderList />
      </div>
    </section>
  );
};

export default Order;
