import React from "react";
import { RxDragHandleDots2 } from "react-icons/rx";
import { ImLocation2 } from "react-icons/im";
const OrdersLocations = ({
  OrderStartCity,
  OrderStartLocation,
  OrderEndCity,
  OrderEndLocatin,
}) => {
  return (
    <ol className="relative border-l m-4  border-gray-200 dark:border-gray-700">
      <li className="mb-1 ml-2">
        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-100 dark:bg-gray-100">
          <ImLocation2 />
        </span>
        <h3 className="flex items-center mb-1 text-sm font-semibold text-gray-900 dark:text-white">
          {OrderStartCity}
        </h3>

        <p className="mb-3 text-base font-normal text-gray-500 dark:text-gray-400">
          {OrderStartLocation}
        </p>
      </li>

      <li className="mb-1 ml-2">
        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
          <ImLocation2 />
        </span>
        <h3 className="flex items-center mb-1 text-sm font-semibold text-gray-900 dark:text-white">
          {OrderEndCity}
        </h3>

        <p className="mb-3 text-base font-normal text-gray-500 dark:text-gray-400">
          {OrderEndLocatin}
        </p>
      </li>
    </ol>
  );
};

export default OrdersLocations;
