import { v4 as uuidv4 } from "uuid";
import AddCurrier from "./components/Curriers/AddCurrier/AddCurrier";
import EditCourier from "./components/Curriers/EditCourier/EditCourier";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import AddOrder from "./components/Order/AddOrder/AddOrder";
import Conditions from "./components/Pages/Conditions/Conditions";
import Couriers from "./components/Pages/Couriers/Couriers";
import Dispatch from "./components/Pages/Dispatch/Dispatch";
import Map from "./components/Pages/Map/Map";
import Orders from "./components/Pages/Orders/Orders";
import Partners from "./components/Pages/Partners/Partners";
import Settings from "./components/Pages/Settings/Settings";
import AddPartner from "./components/Partners/AddPartner/AddPartner";
import PartnerEdit from "./components/Partners/PartnerEdit/PartnerEdit";
import Register from "./components/Register/Register";
import StepFour from "./components/Register/StepFour";
import StepThree from "./components/Register/StepThree";
import StepTwo from "./components/Register/StepTwo";
import MyComponent from "./Demo";
const path_id = uuidv4();
const router = [
  {
    id: path_id,
    name: "Dashboard",
    element: <Dashboard />,
    path: "/",
  },
  {
    id: path_id,
    name: "Login",
    element: <Login />,
    path: "/login",
  },
  {
    id: path_id,
    name: "Register",
    element: <Register />,
    path: "/register",
  },
  {
    id: path_id,
    name: "Register",
    element: <StepTwo />,
    path: "/registertwo",
  },
  {
    id: path_id,
    name: "Register",
    element: <StepThree />,
    path: "/registerthree",
  },
  {
    id: path_id,
    name: "Register",
    element: <StepFour />,
    path: "/registerfour",
  },
  {
    id: path_id,
    name: "Orders",
    element: <Orders />,
    path: "/orders",
  },
  {
    id: path_id,
    name: "Dispatch",
    element: <Dispatch />,
    path: "/dispatch",
  },
  {
    id: path_id,
    name: "Map",
    element: <Map />,
    path: "/map",
  },
  {
    id: path_id,
    name: "Couriers",
    element: <Couriers />,
    path: "/couriers",
  },
  {
    id: path_id,
    name: "EditCourier",
    element: <EditCourier />,
    path: "/couriers/:id",
  },
  {
    id: path_id,
    name: "Counditions",
    element: <Conditions />,
    path: "/conditions",
  },
  {
    id: path_id,
    name: "Partners",
    element: <Partners />,
    path: "/partners",
  },
  {
    id: path_id,
    name: "Partners",
    element: <PartnerEdit />,
    path: "/partners/:id",
  },
  {
    id: path_id,
    name: "AddCurrier",
    element: <AddCurrier />,
    path: "/addcurrier",
  },
  {
    id: path_id,
    name: "Addpartner",
    element: <AddPartner />,
    path: "/addpartner",
  },
  {
    id: path_id,
    name: "Setting",
    element: <Settings />,
    path: "/setting",
  },
  {
    id: path_id,
    name: "Setting",
    element: <Settings />,
    path: "/setting/:id",
  },
  {
    id: path_id,
    name: "Add Order",
    element: <AddOrder />,
    path: "/addorder",
  },
];

export default router;
