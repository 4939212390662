import React from "react";
import axios from "axios";
import Logo from "../../assets/Peagle PP.jpg";
import Gmail from "../../assets/Google.svg";
import GmailIcon from "../../assets/Icon.svg";
import Unlock from "../../assets/unlock.svg";
import Lock from "../../assets/lock.svg";
import clsx from "clsx";
import styles from "./register.module.scss";
import { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Spinner from "../Spinner/Spinner";
import BasicAlerts from "../Alert/Alert";
import { IoWarning } from "react-icons/io5";
import succes from "../../assets/success.svg";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const [lock, setLock] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setIsloading] = useState(false);
  const [value, setValue] = useState({
    email: "",
    password: "",
  });
  const [err, setErr] = useState("");
  const [status, setStatus] = useState(false);

  const SetChangeEye = () => {
    setLock(!lock);
  };

  const handleChnageChecbox = () => {
    setDisabled(!disabled);
  };

  var SubmitArry = [];
  const HandlePost = async (e) => {
    e.preventDefault();
    setIsloading(true);
    if (
      value.email === "" &&
      value.password === "" &&
      value.email.includes("@") === false &&
      value.password.length < 8
    ) {
      setErr("Email ve passwordu daxil edin !!!");
    }
    setTimeout(() => {
      setIsloading(false);
      setErr(false);
    }, 1000);
    SubmitArry.push(value);
    const PostURL = "https://app.peagle.app/auth/register";

    await axios
      .post(PostURL, value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          navigate("/registertwo");
        }
      });

    localStorage.setItem("user", JSON.stringify(SubmitArry));
  };

  const KeyPresss = (event) => {
    if (event.target.value.length >= 0 && event.target.value.length < 5) {
      setStatus(true);
    } else if (event.target.value.length > 5) {
      setStatus(false);
    }
  };

  return (
    <section className={styles.register}>
      <div className="container-fluid">
        <div className="row">
          <div className={clsx("col-xl-5 offset-xl-1 g-0")}>
            <div className={styles.left_side}>
              <div className={styles.head}>
                <img src={Logo} alt="" />
                <h2>Peagle</h2>
              </div>
              <div className={styles.title}>
                <h2>Hi, Let’s get started!</h2>
                <p>Lorem ipsum dolor sit amet, consectetur </p>
              </div>
              <div className={styles.google_login}>
                <button>
                  <img src={Gmail} alt="" />
                  Continue with Google
                </button>
              </div>
              <div className={styles.forms}>
                <form onSubmit={HandlePost}>
                  <label htmlFor="E-mail Address">E-mail Address</label>
                  <div className={styles.form_input}>
                    <Input
                      onChange={(e) =>
                        setValue({ ...value, email: e.target.value })
                      }
                      type="text"
                      placeholder="marthe@friendly.com"
                      onKeyDown={(event) => {
                        KeyPresss(event);
                      }}
                      className={status ? styles.error : styles.success}
                    />
                    {err ? <p>{err}</p> : ""}

                    {status ? (
                      <div className={styles.status}>
                        <IoWarning />
                      </div>
                    ) : (
                      <div className={styles.status}>
                        <img src={succes} alt="" />
                      </div>
                    )}

                    <div className={styles.email}>
                      <img src={GmailIcon} alt="" />
                    </div>
                  </div>
                  <label htmlFor="Password">Password</label>
                  <div className={styles.form_input}>
                    <Input
                      onChange={(e) =>
                        setValue({ ...value, password: e.target.value })
                      }
                      onKeyDown={(event) => {
                        KeyPresss(event);
                      }}
                      type={lock ? "password" : "text"}
                      placeholder="Enter password"
                      className={status ? styles.error : styles.success}
                    />
                    {err ? <p>{err}</p> : ""}
                    {status ? (
                      <div className={styles.status}>
                        <IoWarning />
                      </div>
                    ) : (
                      <div className={styles.status}>
                        <img src={succes} alt="" />
                      </div>
                    )}

                    {lock ? (
                      <div className={styles.lock}>
                        <img src={Lock} alt="" />
                      </div>
                    ) : (
                      <div className={styles.un_lock}>
                        <img src={Unlock} alt="" />
                      </div>
                    )}

                    <div onClick={SetChangeEye} className={styles.eye}>
                      <BsFillEyeFill color={!lock ? "grey" : "black"} />
                    </div>
                  </div>
                  <div className={styles.forget_password}>
                    <div className={styles.check_data}>
                      <Input onChange={handleChnageChecbox} type="checkbox" />
                      <p>Remember me</p>
                    </div>
                    <div className={styles.recover}>
                      <span> Forgot Password?</span>
                      <Link>Recover</Link>
                    </div>
                  </div>
                  <Button
                    onClick={(() => setIsloading(true), HandlePost)}
                    disabled={disabled}
                    title="Continue"
                  >
                    {loading ? (
                      <Spinner className={"text-light spinner-border-sm"} />
                    ) : (
                      ""
                    )}
                  </Button>

                  <div className={styles.sign_up}>
                    <span>
                      Don’t have an account? <Link to="/login">Sign In</Link>
                    </span>
                  </div>
                  {err && <BasicAlerts title={err} type="error" />}
                </form>
              </div>
            </div>
          </div>
          <div className={clsx("col-xl-6 g-0")}>
            <div className={styles.bg_color}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
