import React from "react";
import styled from "./DashboardStatistc.module.scss";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
const DashboardStatistic = ({
  icon,
  percentage,
  Price,
  Title,
  TotalPrice,
  ...props
}) => {
  return (
    <div {...props} className="col-xl-4  col-md-6 col-sm-12 g-3">
      <div className={styled.total_score}>
        <div className={styled.top_side}>
          <h4>{Title}</h4>
          <p
            style={{
              backgroundColor: parseInt(percentage) < 20 && "#FFEEED",
              color: parseInt(percentage) < 20 && "#F04438",
            }}
          >
            {percentage < 20 ? "-" : "+"}
            {percentage}%
            {parseInt(percentage) < 20 ? (
              <BsArrowDownShort size={20} />
            ) : (
              <BsArrowUpShort size={20} />
            )}
          </p>
        </div>
        <div className={styled.total_price}>
          <h2>${Price}</h2>
        </div>
      </div>
    </div>
  );
};

export default DashboardStatistic;
