import React from "react";
import "./dispatch.scss";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FaMap } from "react-icons/fa";
import DispatchDrivers from "../../Dispatch/DispatchDrivers/DispatchDrivers";
import DispatchAssignedOrders from "../../Dispatch/DIspatchAssignedOrders/DispatchAssignedOrders";
import DispatchUnassignOrders from "../../Dispatch/DispatchUnassignedOrders/DispatchUnassignOrders";
const Dispatch = () => {
  return (
    <section className="my-3" id="dispatch">
      <div className="container mb-5">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-3">
            <h1>Dispatch</h1>
            <p>Track, manage and forecast your orders.</p>
          </div>
          <div className="col-xl-3 g-3 text-end">
            <div className="rigth_side">
              <button>
                <AiOutlineUnorderedList />
                List
              </button>
              <button>
                <FaMap />
                Map
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div
            className="col-xl-3 p-0"
            style={{
              borderRight: "1px solid #e5e5e5",
              borderTop: "1px solid #e5e5e5",
            }}
          >
            <DispatchDrivers />
          </div>
          <div className="col-xl-9 ">
            <div className="row">
              <div
                className="col-xl-6 p-0"
                style={{
                  borderRight: "1px solid #e5e5e5",
                  borderTop: "1px solid #e5e5e5",
                }}
              >
                <DispatchAssignedOrders />
              </div>
              <div
                className="col-xl-6 p-0"
                style={{
                  borderRight: "1px solid #e5e5e5",
                  borderTop: "1px solid #e5e5e5",
                }}
              >
                <DispatchUnassignOrders />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dispatch;
