import React from "react";
import styled from "./SettingTabs.module.scss";

const SettingBannerComponents = ({ Heading, Title, ...props }) => {
  return (
    <div {...props} className="row">
      <div className="col-xl-12">
        <div className="row align-items-center">
          <div className="col-xl-8">
            <div className={styled.heading}>
              <h2>{Heading}</h2>
              <p>{Title}</p>
            </div>
          </div>
          <div className="col-xl-4">
            <div className={styled.btns_group}>
              <button className={styled.cancel}>Cancel</button>
              <button className={styled.save}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingBannerComponents;
