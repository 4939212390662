import React from "react";
import styled from "../DIspatchAssignedOrders/DispatchAssignedOrder.module.scss";
import DispatchOrders from "../DispatchOrders/DispatchOrder";
import OrdersLocations from "../DispatchOrders/OrdersLocations";
import { useGET_ALL_UNASSIGNEDQuery } from "../../../redux/features/api/Dispatch";
import { RotatingLines } from "react-loader-spinner";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const DispatchUnassignOrders = () => {
  const { data, isLoading, isError, isSuccess } = useGET_ALL_UNASSIGNEDQuery();
  if (isLoading)
    return (
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="1.75"
        width="26"
        visible={true}
      />
    );
  if (isError) return <h1>Error...</h1>;
  let renderData = null;
  if (isSuccess && data) {
    renderData =
      data &&
      data.data.map((data, index) => {
        let orderWayStatus;

        switch (data.status) {
          case 1:
            orderWayStatus = "on the way";
            break;
          case 2:
            orderWayStatus = "delivered";
            break;
          case 3:
            orderWayStatus = "canceled";
            break;
          default:
            orderWayStatus = "on the way";
        }
        return (
          <DispatchOrders
            key={index}
            OrderNumber={data.order_number}
            OrderStatus={"Assign"}
            OrderWay_Status={orderWayStatus}
            OrderPrice={`$${data.total_price}`}
          >
            <OrdersLocations
              OrderStartCity={"Siberian Wellness"}
              OrderStartLocation={"9RCR+347 R.Akhundov, Baku, Azerbaijan"}
              OrderEndCity={"Siberian Wellness"}
              OrderEndLocatin={"9RCR+347 R.Akhundov, Baku, Azerbaijan"}
            />
          </DispatchOrders>
        );
      });
  }
  return (
    <section className={styled.dispatch_assigned_orders}>
      <h1>Unassigned Orders</h1>
      <div className="scroll">{renderData}</div>
    </section>
  );
};

export default DispatchUnassignOrders;
