import React from "react";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plusIcon.svg";
import styles from "./index.module.scss";
const StartCreation = ({ handleCreate }) => {
  return (
    <div className={styles.element}>
      <div className={styles.iconWrapper}>
        <PlusIcon />
      </div>
      <div>
        <p>Create condition</p>
        <p>Select a condition to start</p>
      </div>
      <button onClick={handleCreate}>Select condition</button>
    </div>
  );
};

export default StartCreation;
