import React from "react";
import Partner from "../../Partners/Partners";
const Partners = () => {
  return (
    <div>
      <Partner />
    </div>
  );
};

export default Partners;
