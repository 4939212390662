import React from "react";
import styled from "./DispatchOrder.module.scss";
import { RxDragHandleDots2 } from "react-icons/rx";
const DispatchOrders = ({
  OrderNumber,
  OrderStatus,
  OrderWay_Status,
  OrderPrice,
  children,
  ...props
}) => {
  return (
    <div {...props} className={styled.order_assign}>
      <div>
        <div className={styled.assign_header}>
          <div className={styled.order_number}>
            <p>{OrderNumber}</p>
          </div>
          <div className={styled.right_side}>
            <button>{OrderStatus}</button>
            <button>
              <RxDragHandleDots2 size={24} />
            </button>
          </div>
        </div>
        <div className={styled.order_status}>
          <span className={styled.on_the_way}>{OrderWay_Status}</span>
          <h4>{OrderPrice}</h4>
        </div>

        {children}
      </div>
    </div>
  );
};

export default DispatchOrders;
