import React from "react";
import { useGET_ALL_COURIERSQuery } from "../../../redux/features/api/Couriers";
import styled from "./DIspatchDriver.module.scss";
import { RotatingLines } from "react-loader-spinner";
const DispatchDrivers = () => {
  const { data, isError, isLoading, isSuccess } = useGET_ALL_COURIERSQuery();
  if (isLoading)
    return (
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="1.75"
        width="26"
        visible={true}
      />
    );
  if (isError) return <h1>Error...</h1>;
  let couriers = null;
  if (isSuccess && data) {
    couriers =
      data &&
      data.data.map((data, index) => {
        return (
          <div key={index} className={styled.card_avatar}>
            <div className={styled.user_name}>
              {data.fullname.substring(0, 2)}
            </div>
            <div className={styled.user_table}>
              <h3>{data.fullname}</h3>
              <h5>{data.email}</h5>
            </div>
            <div className={styled.count}>
              <p>{data.count || 0}</p>
            </div>
          </div>
        );
      });
  }
  return (
    <section className={styled.dispatch_drivers}>
      <h1>Drivers</h1>
      <div className="scroll">{couriers}</div>
    </section>
  );
};

export default DispatchDrivers;
