import React from "react";
import { BsArrowUpRightSquare } from "react-icons/bs";
import "./orderDashboard.scss";
import { useGET_ALL_ORDERSQuery } from "../../redux/features/api/Order";
const DashBoardOrder = () => {
  const {
    data: order,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGET_ALL_ORDERSQuery();
  return (
    <summary id="dashborard_order">
      <div className="order">
        <h5>Orders</h5>
        <BsArrowUpRightSquare />
      </div>
      <h2>{order && order.data.length}</h2>
      <div id="data_table">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Customer</th>
              <th scope="col">Number of orders</th>
            </tr>
          </thead>
          <tbody>
            {order &&
              order.data.map((element, index) => (
                <tr key={index}>
                  <th scope="row">{(index += 1)}</th>
                  <td>
                    <div className="avatar">
                      {" "}
                      <h5>{element.delivery.fullname}</h5>
                    </div>
                  </td>
                  <td>{element.order_number}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </summary>
  );
};

export default DashBoardOrder;
