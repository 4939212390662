import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";

const Header = ({ cancelHandler, saveHandler, isSaveDisabled }) => {
  return (
    <div className={styles.element}>
      <div className={styles.left}>
        <p>Conditions</p>
        <p>A better way to automate your conditions.</p>
      </div>
      <div className={styles.right}>
        <button className={styles.button} onClick={cancelHandler}>
          Cancel
        </button>
        <button
          disabled={isSaveDisabled}
          className={cn(styles.button, !isSaveDisabled && styles.active)}
          onClick={saveHandler}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Header;
