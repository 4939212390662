import React from "react";
import "./settigntabs.scss";
import clsx from "clsx";
import SettingBannerComponents from "./SettingBannerComponents";
import SettingTabButtons from "./SettingTabButtons";
import styled from "./SettingTabs.module.scss";
const SettingTabs = () => {
  const [distancebtn, activeDistanceBtn] = React.useState("active_btn");
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-xl-3 g-3">
            <SettingTabButtons />
          </div>

          <div className="col-xl-9 g-3">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabIndex="0"
              >
                <SettingBannerComponents
                  Heading="Preferences"
                  Title="Set your preferences for the app."
                />
                <div className={styled.prefence_forms}>
                  <form>
                    <div className={clsx("br_bottom", styled.form_heading)}>
                      <h2>Location</h2>
                      <p>Choose how you want to calculate.</p>
                    </div>

                    <div className={clsx("br_bottom", styled.choose_country)}>
                      <div className="country_head">
                        <h4>Country</h4>
                        <p>Subtitle</p>
                      </div>
                      <select name="" id="">
                        <option value="france">France</option>
                        <option value="azerbaijan">Azerbaijan</option>
                        <option value="trukey">Turkey</option>
                      </select>
                    </div>
                    <div className={clsx("br_bottom", styled.choose_country)}>
                      <div className="country_head">
                        <h4>City</h4>
                        <p>Subtitle</p>
                      </div>
                      <select name="" id="">
                        <option value="azerbaijan">Azerbaijan</option>
                        <option value="france">France</option>
                        <option value="trukey">Turkey</option>
                      </select>
                    </div>
                    <div className={clsx("br_bottom", styled.choose_country)}>
                      <div className="country_head">
                        <h4>Time-Zona</h4>
                        <p>Subtitle</p>
                      </div>
                      <select name="" id="">
                        <option value="baku">Baku</option>
                        <option value="france">France</option>
                        <option value="trukey">Turkey</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className={styled.prefence_forms}>
                  <form>
                    <div className={clsx("br_bottom", styled.form_heading)}>
                      <h2>Unit Preferences</h2>
                      <p>Choose how you want to calculate.</p>
                    </div>

                    <div className={clsx("br_bottom", styled.distance)}>
                      <div className={styled.distance_unit}>
                        <h4>Distance unit</h4>
                        <p>Subtitle</p>
                      </div>
                      <div className={styled.select_distance}>
                        <button className={styled[distancebtn]}>KM</button>
                        <button>MILE</button>
                      </div>
                    </div>
                    <div className={clsx("br_bottom", styled.distance)}>
                      <div className={styled.distance_unit}>
                        <h4>Weight unit</h4>
                        <p>Subtitle</p>
                      </div>
                      <div className={styled.select_distance}>
                        <button className={styled[distancebtn]}>KG</button>
                        <button>LB</button>
                      </div>
                    </div>
                    <div className={clsx("br_bottom", styled.choose_country)}>
                      <div className="country_head">
                        <h4>Currency</h4>
                        <p>Subtitle</p>
                      </div>
                      <select name="" id="">
                        <option value="baku">USA</option>
                        <option value="france">FR</option>
                        <option value="trukey">TR</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div className={styled.prefence_forms}>
                  <form>
                    <div className={clsx("br_bottom", styled.form_heading)}>
                      <h2>Business Settings</h2>
                      <p>
                        Choose what you do and how you do to make your
                        experience better.
                      </p>
                    </div>

                    <div className={clsx("br_bottom", styled.distance)}>
                      <div className={styled.distance_unit}>
                        <h4>Business type</h4>
                        <p>Subtitle</p>
                      </div>
                      <div className={styled.check_box}>
                        <button type="input" className={clsx("check_btn")}>
                          <div className={styled.text_btn}>
                            <p>Pickup and Delivey</p>
                            <p>Includes up to 10 users </p>
                          </div>
                          <input type="radio" name="select" />
                        </button>
                        <button type="input" className={clsx("check_btn")}>
                          <div className={styled.text_btn}>
                            <p>Pickup and Delivey</p>
                            <p>Includes up to 10 users </p>
                          </div>
                          <input type="radio" name="select" />
                        </button>
                      </div>
                    </div>
                    <div className={clsx("br_bottom", styled.distance)}>
                      <div className={styled.distance_unit}>
                        <h4>Working hours</h4>
                        <p>Choose your working hours.</p>
                      </div>
                      <div className={styled.work_hours}>
                        <button className={styled.working_active_btn}>
                          Selected hours
                        </button>
                        <button>Always open</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex="0"
              >
                <SettingBannerComponents
                  Heading="Customer Settings"
                  Title="Set your preferences for the app."
                />
              </div>

              <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
                tabIndex="0"
              >
                <SettingBannerComponents
                  Heading="Drivers and Dispatch"
                  Title="Set your preferences for the app."
                />
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
                tabIndex="0"
              >
                <SettingBannerComponents
                  Heading="Route Settings"
                  Title="Set your preferences for the app."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingTabs;
