import React from "react";
import axios from "axios";
import Logo from "../../assets/Peagle PP.jpg";
import Gmail from "../../assets/Google.svg";
import GmailIcon from "../../assets/Icon.svg";
import Unlock from "../../assets/unlock.svg";
import Lock from "../../assets/lock.svg";
import clsx from "clsx";
import styles from "./login.module.scss";
import { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Spinner from "../Spinner/Spinner";
import BasicAlerts from "../Alert/Alert";
import { IoWarning } from "react-icons/io5";
import succes from "../../assets/success.svg";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [lock, setLock] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setIsloading] = useState(false);
  const [value, setValue] = useState({
    email: "",
    password: "",
  });
  const [err, setErr] = useState("");
  const [status, setStatus] = useState(false);
  const SetChangeEye = () => {
    setLock(!lock);
  };

  const handleChnageChecbox = () => {
    setDisabled(!disabled);
  };

  var SubmitArry = [];
  const HandlePost = async (e) => {
    e.preventDefault();
    setIsloading(true);
    if (
      value.email === "" &&
      value.password === "" &&
      value.email.includes("@") === false
    ) {
      setErr("Email ve passwordu daxil edin !!!");
    }
    setTimeout(() => {
      setIsloading(false);
      setErr(false);
    }, 1000);
    SubmitArry.push(value);
    const PostURL = "https://app.peagle.app/auth/login";

    await axios
      .post(PostURL, value, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 403) {
          setErr("Email ve ya password yanlisdir !!!");
        }
        if (res.status === 200) {
          localStorage.setItem("login_token", res.data.data);
          navigate("/");
        }
      })
      .catch((err) => {
        setErr("Email ve ya password yanlisdir !!!");
      });

    localStorage.setItem("user", JSON.stringify(SubmitArry));
    // const token =
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmIzOTEzNTlkZjk1NzdhNmJjNTdkYTBjZDI0MWM0OGYwMDBjYjE4NjA5ZTJjYWJlM2EyZjA5ODEyYzFlM2VjZjhjZTVlZTZkN2JhMGRlN2MiLCJpYXQiOjE2ODgyMTQzNDYuMDk4MDAyOTEwNjE0MDEzNjcxODc1LCJuYmYiOjE2ODgyMTQzNDYuMDk4MDA0MTAyNzA2OTA5MTc5Njg3NSwiZXhwIjoxNzE5ODM2NzQ2LjA5Njg3OTAwNTQzMjEyODkwNjI1LCJzdWIiOiIyNSIsInNjb3BlcyI6W119.k7HkF1v3KOpT9WGgE9CGYy_vFw-t08M6T4SYDAhx3WLIk2o1Sc_lOPap2BQcQf_AUMfdyjIqUMvgpWebfpNtG1JFZAFssxr8R6Fofm5YZmRRw5vg3s3V0yOtBrirXujowhKcZ1tPUy2n1jaT-9frLOQYrCXId8FC6Ue_TsL3y_CuidRNjmbPlzZcKPDmxlEBKuy_D-U5B-7Rpv9L1uCyBdOtFY3zG-BqKNROL2pRSZZlJTg-STRL_IlzihvtLvclSlFY1YFoZVb9gbFcCZN-DFZ4bCPZOrUtWC_-ro1pBcug_w4_k_gSsMRRMRXJsEU6n_4LKR9V_IlizSw8CqfFt6931WZqzlESU2r4bUfxWzPpLgMhFLfDax4IhLmoxWrcmIhMOaOwdg3s30ZTiJsLb-lajo5L4AU93dXfL9hLGleZTvZXN1zy0MMgVXBl3hgnv2M4-ZIOGpGm9yjR3msxV01Vz56ZLiNpGERSQwMUT5CEW4ugfNyhDg9i9eVWEYHh07KkwWEFddWS-u20wJWnrEB0vQvx1b9C6kd8X7qkzxsPDEbs079qLHZCW9RmC2gs9j8d7CSLXaFrNrIEmRq5sOFuBxiqU5qIpGVQAyRCkcO1Tgkr7IHT3azGg_-o_a8QN4sHa0jaFd4TUkdZv8N1iNbmB4_LQi-q2EPbrvzqUUM";

    // axios
    //   .get("https://app.peagle.app/api/order/all", {
    //     headers: {
    //       Authorization: localStorage.getItem("login_token"),
    //       Accept: "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const KeyPresss = (event) => {
    if (event.target.value.length >= 0 && event.target.value.length < 5) {
      setStatus(true);
    } else if (event.target.value.length > 5) {
      setStatus(false);
    }
  };

  return (
    <section className={styles.register}>
      <div className="container-fluid">
        <div className="row">
          <div className={clsx("col-xl-5 offset-xl-1 g-0")}>
            <div className={styles.left_side}>
              <div className={styles.head}>
                <img src={Logo} alt="" />
                <h2>Peagle</h2>
              </div>
              <div className={styles.title}>
                <h2>Hi, Welcome back</h2>
                <p>Lorem ipsum dolor sit amet, consectetur </p>
              </div>
              <div className={styles.google_login}>
                <button>
                  <img src={Gmail} alt="" />
                  Continue with Google
                </button>
              </div>
              <div className={styles.forms}>
                <form onSubmit={HandlePost}>
                  <label htmlFor="E-mail Address">E-mail Address</label>
                  <div className={styles.form_input}>
                    <Input
                      onChange={(e) =>
                        setValue({ ...value, email: e.target.value })
                      }
                      type="text"
                      placeholder="marthe@friendly.com"
                      onKeyDown={(event) => {
                        KeyPresss(event);
                      }}
                      className={status ? styles.error : styles.success}
                    />
                    {err ? <p>{err}</p> : ""}

                    {status ? (
                      <div className={styles.status}>
                        <IoWarning />
                      </div>
                    ) : (
                      <div className={styles.status}>
                        <img src={succes} alt="" />
                      </div>
                    )}

                    <div className={styles.email}>
                      <img src={GmailIcon} alt="" />
                    </div>
                  </div>
                  <label htmlFor="Password">Password</label>
                  <div className={styles.form_input}>
                    <Input
                      onChange={(e) =>
                        setValue({ ...value, password: e.target.value })
                      }
                      onKeyDown={(event) => {
                        KeyPresss(event);
                      }}
                      type={lock ? "password" : "text"}
                      placeholder="Enter password"
                      className={status ? styles.error : styles.success}
                    />
                    {err ? <p>{err}</p> : ""}
                    {status ? (
                      <div className={styles.status}>
                        <IoWarning />
                      </div>
                    ) : (
                      <div className={styles.status}>
                        <img src={succes} alt="" />
                      </div>
                    )}

                    {lock ? (
                      <div className={styles.lock}>
                        <img src={Lock} alt="" />
                      </div>
                    ) : (
                      <div className={styles.un_lock}>
                        <img src={Unlock} alt="" />
                      </div>
                    )}

                    <div onClick={SetChangeEye} className={styles.eye}>
                      <BsFillEyeFill color={!lock ? "grey" : "black"} />
                    </div>
                  </div>
                  <div className={styles.forget_password}>
                    <div className={styles.check_data}>
                      <Input onChange={handleChnageChecbox} type="checkbox" />
                      <p>Remember me</p>
                    </div>
                    <div className={styles.recover}>
                      <span> Forgot Password?</span>
                      <Link>Recover</Link>
                    </div>
                  </div>
                  <Button
                    onClick={(() => setIsloading(true), HandlePost)}
                    disabled={disabled}
                    title="Continue"
                  >
                    {loading ? (
                      <Spinner className={"text-light spinner-border-sm"} />
                    ) : (
                      ""
                    )}
                  </Button>

                  <div className={styles.sign_up}>
                    <span>
                      Don’t have an account? <Link to="/register">Sign Up</Link>
                    </span>
                  </div>
                  {err && <BasicAlerts title={err} type="error" />}
                </form>
              </div>
            </div>
          </div>
          <div className={clsx("col-xl-6 g-0")}>
            <div className={styles.bg_color}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
