import React from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import styled from "./BannerComponent.module.scss";
const BannerComponent = ({
  HeadingName,
  HeadingText,
  ButtonCancel,
  ButtonSave,
  children,
  ...props
}) => {
  return (
    <div {...props}>
      <div className="container py-5">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-4 g-3">
            <div className={styled.left_side}>
              <RiArrowLeftSLine
                cursor="Pointer"
                onClick={() => window.history.back()}
              />
              <div className={styled.left_text}>
                <h1>{HeadingName}</h1>
                <p>{HeadingText}</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 g-3">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
