import React, { useState } from "react";
import BannerComponent from "../../BannerComonent/BannerComponent";
import styled from "./AddOrder.module.scss";
import { BsFillTelephoneFill } from "react-icons/bs";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Icon from "../../Icon/Icon";

const AddPartner = () => {
  const Navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [courrier, setCourrier] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");

  const newCouriers = [];

  courrier.map((item) => {
    newCouriers.push({ name: item.fullname, value: item.id });
  });
  useEffect(() => {
    axios.get("https://app.peagle.app/api/courier/all").then((res) => {
      setCourrier(res.data.data);
    });
  }, []);
  const [form, setForm] = useState({
    order_number: "",
    total_price: "",
    courier_fee: "",
    payment_method: "",
    additional_note: "",
    item: [],
    pick_up_fullname: "",
    pick_up_phone: "",
    pick_up_email: "",
    pick_up_date: null,
    pick_up_time: "",
    pick_up_latitude: "49.8235239",
    pick_up_longitude: "44.8235239",
    delivery_fullname: "",
    delivery_phone: "",
    delivery_email: "",
    delivery_latitude: "49.8235239",
    delivery_longitude: "44.8235239",
    delivery_date: null,
    delivery_time: "",
    delivery_address: "",
    pick_up_address: "",
  });
  const [fields, setFields] = useState([{ name: "", count: "", weight: "" }]);
  const HandleDeleteFields = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const HandleChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
    console.log(fields);
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  console.log(paymentMethod);

  const addField = () => {
    setFields([...fields, { name: "", count: "", weight: "" }]);
  };

  const handleItemChange = (event, index) => {
    // setSelectedOption(event.target.value);
    const value = event.target.value;
    setForm({
      ...form,
      [event.target.name]: value,
    });
  };
  const handleOptionChange = (e) => {
    setSelectedCities(e.value);
  };

  const calculateTotalCount = () => {
    let totalCount = 0;
    for (const field of fields) {
      totalCount += parseInt(field.count, 10) || 0;
    }
    return totalCount;
  };

  const calculateTotalWeight = () => {
    let totalWeight = 0;
    for (const field of fields) {
      totalWeight += parseFloat(field.weight) || 0;
    }
    return totalWeight;
  };
  const HandlePost = (e) => {
    const newForm = (form.item = fields);
    form.payment_method = paymentMethod;
    setForm(...newForm, paymentMethod);
    if (
      form.delivery_fullname === "" ||
      form.pick_up_fullname === "" ||
      form.delivery_address === "" ||
      form.pick_up_address === "" ||
      form.delivery_phone === "" ||
      form.pick_up_phone === ""
    ) {
      toast.error("Please fill all fields");
      return;
    }

    setIsLoading(true);
    axios
      .post("https://app.peagle.app/api/order/store", form, {
        headers: {
          Authorization: localStorage.getItem("login_token"),
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.status == 400 || res.status == 500) {
          toast.error("Something went wrong");
        }
        if (res.status === 200) {
          Navigate("/orders");
          window.location.reload();
          setForm({});
          toast.success("Order created successfully");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
    console.log(form);
  };
  return (
    <div>
      <BannerComponent HeadingName="New Order" HeadingText="Back to Order list">
        {" "}
        <div className={styled.btns_group}>
          <button
            onClick={() => window.history.back()}
            className={styled.cancel_btn}
          >
            Cancel
          </button>

          <div onClick={() => HandlePost()}>
            {loading ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            ) : (
              <button className={styled.save_btn}>Save</button>
            )}
          </div>
        </div>
      </BannerComponent>
      <div className="container">
        <div className="row  justify-content-center">
          <div className="col-xl-6">
            <div className={styled.forms_elements}>
              <div className={styled.driver_details}>
                <h2>Order details</h2>
                <p>Placeholder</p>
              </div>
              <form action="">
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Order number</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="order_number"
                    id="name"
                    placeholder="Enter order number"
                    onChange={handleItemChange}
                    value={form.order_number}
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Total Price</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.concat_input}>
                    <input
                      type="number"
                      name="total_price"
                      id="name"
                      placeholder="Enter price"
                      onChange={handleItemChange}
                      value={form.total_price}
                    />
                    <select name="" id="">
                      <option value="AZN">AZN</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                    </select>
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Delivery fee </label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.concat_input}>
                    <input
                      type="number"
                      name="courier_fee"
                      id="name"
                      placeholder="Enter delivery fee"
                      onChange={handleItemChange}
                      value={form.courier_fee}
                    />
                    <select name="" id="">
                      <option value="AZN">AZN</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                    </select>
                  </div>
                </div>
                <div className={styled.radio}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Payment method</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.radio_input}>
                    <div
                      className={`flex items-center pl-4 border ${
                        paymentMethod === "1"
                          ? "bg-blue-200"
                          : "border-gray-200"
                      } rounded dark:border-gray-700 px-3`}
                    >
                      <label
                        htmlFor="bordered-radio-1"
                        className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Cash on delivery
                      </label>
                      <input
                        checked={paymentMethod === "1"}
                        onChange={handlePaymentMethodChange}
                        id="bordered-radio-1"
                        type="radio"
                        value="1"
                        name="payment_method"
                        className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ${
                          paymentMethod === "1" ? "focus:bg-blue-200" : ""
                        }`}
                      />
                    </div>

                    <div
                      className={`flex items-center pl-4 border ${
                        paymentMethod === "2"
                          ? "bg-blue-200"
                          : "border-gray-200"
                      } rounded dark:border-gray-700 px-3`}
                    >
                      <label
                        htmlFor="Debit/Credit card"
                        className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Credit/Debit card
                      </label>
                      <input
                        onChange={handlePaymentMethodChange}
                        checked={paymentMethod === "2"}
                        id="bordered-radio-2"
                        type="radio"
                        value="2"
                        name="payment_method"
                        className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 ${
                          paymentMethod === "2" ? "focus:bg-blue-200" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Additional Note</label>
                    <p>Subtitle</p>
                  </div>
                  <textarea
                    onChange={handleItemChange}
                    value={form.additional_note}
                    placeholder="enter here"
                    name="additional_note"
                    id="additional_note"
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="row mt-4">
              <div className="col-xl-12">
                <div className={styled.forms_elements}>
                  <div className={styled.driver_details}>
                    <h2>Items</h2>
                    <p>Placeholder</p>
                  </div>
                  <div className={styled.items}>
                    <div className="row align-items-center">
                      {fields.map((el, indx) => (
                        <React.Fragment>
                          {" "}
                          <div className="row my-2">
                            <div className="col-xl-6">
                              <input
                                type="text"
                                value={el.name}
                                placeholder="name"
                                aria-required
                                onChange={(e) =>
                                  HandleChange(indx, "name", e.target.value)
                                }
                              />
                            </div>
                            <div className="col-xl-3">
                              <select
                                value={el.count}
                                name=""
                                id=""
                                defaultValue={1}
                                onChange={(e) =>
                                  HandleChange(indx, "count", e.target.value)
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </div>
                            <div className="col-xl-2">
                              <div className={styled.right_side}>
                                <input
                                  value={el.weight}
                                  onChange={(e) =>
                                    HandleChange(indx, "weight", e.target.value)
                                  }
                                  type="number"
                                />
                                <button>KG</button>
                              </div>
                            </div>
                            <div className="col-xl-1">
                              <button
                                onClick={() => HandleDeleteFields(indx)}
                                className={styled.delete_items}
                              >
                                <Icon Name="close" />
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}

                      <div className="col-xl-12">
                        <button onClick={addField} className={styled.add_item}>
                          Add Item
                        </button>
                      </div>
                      <div className="row my-3 justify-content-between">
                        <div className="col-xl-3">
                          <h5 className={styled.total_w}>Total Weight</h5>
                        </div>
                        <div className="col-xl-3">
                          <div className={styled.total}>
                            <h5>{calculateTotalWeight()}</h5>
                            <button>KG</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className={styled.forms_elements}>
              <div className={styled.driver_details}>
                <h2>Pickup details</h2>
                <p>Placeholder</p>
              </div>
              <form>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Name</label>
                    <p>Subtitle</p>
                  </div>
                  <input
                    type="text"
                    name="pick_up_fullname"
                    id="fullname"
                    placeholder="Enter store name"
                    value={form.pick_up_fullname}
                    onChange={handleItemChange}
                    required
                  />
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Phone number</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="tel"
                      name="pick_up_phone"
                      id="phone"
                      minLength="5"
                      maxLength="200"
                      placeholder="6 00 00 00 00"
                      value={form.pick_up_phone}
                      required
                      onChange={handleItemChange}
                    />
                    <BsFillTelephoneFill color="#667085" size={16} />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Email address</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="email"
                      name="pick_up_email"
                      id="email"
                      placeholder="marthe@friendly.com"
                      value={form.pick_up_email}
                      required
                      onChange={handleItemChange}
                    />
                    <Icon Name="envelope" />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">PickUp address</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <input
                      type="text"
                      name="pick_up_address"
                      id="pick_up_addres"
                      placeholder="Baku"
                      value={form.pick_up_address}
                      onChange={handleItemChange}
                      required
                    />
                    <Icon Name="map" />
                  </div>
                </div>
                <div className={styled.name}>
                  <div className={styled.name_input}>
                    <label htmlFor="name">Date & Time</label>
                    <p>Subtitle</p>
                  </div>
                  <div className={styled.email_input}>
                    <div className={styled.times}>
                      <input
                        type="date"
                        name="pick_up_date"
                        placeholder="select the time"
                        value={form.pick_up_date}
                        onChange={handleItemChange}
                      />
                      <input
                        type="time"
                        name="pick_up_time"
                        placeholder="select the time"
                        value={form.pick_up_time}
                        onChange={handleItemChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="row mt-4">
              <div className="col-xl-12">
                <div className={styled.forms_elements}>
                  <div className={styled.driver_details}>
                    <h2>Delivery details </h2>
                    <p>Placeholder</p>
                  </div>
                  <form>
                    <div className={styled.name}>
                      <div className={styled.name_input}>
                        <label htmlFor="name">Name</label>
                        <p>Subtitle</p>
                      </div>
                      <input
                        type="text"
                        name="delivery_fullname"
                        id="fullname"
                        placeholder="Enter customer name"
                        value={form.delivery_fullname}
                        onChange={handleItemChange}
                        required
                      />
                    </div>
                    <div className={styled.name}>
                      <div className={styled.name_input}>
                        <label htmlFor="name">Phone number</label>
                        <p>Subtitle</p>
                      </div>
                      <div className={styled.email_input}>
                        <input
                          type="tel"
                          name="delivery_phone"
                          id="phone"
                          minLength="5"
                          maxLength="200"
                          placeholder="6 00 00 00 00"
                          value={form.delivery_phone}
                          onChange={handleItemChange}
                          required
                        />
                        <BsFillTelephoneFill color="#667085" size={16} />
                      </div>
                    </div>
                    <div className={styled.name}>
                      <div className={styled.name_input}>
                        <label htmlFor="name">Email address</label>
                        <p>Subtitle</p>
                      </div>
                      <div className={styled.email_input}>
                        <input
                          type="email"
                          name="delivery_email"
                          id="email"
                          placeholder="marthe@friendly.com"
                          value={form.delivery_email}
                          required
                          onChange={handleItemChange}
                        />
                        <Icon Name="envelope" />
                      </div>
                    </div>
                    <div className={styled.name}>
                      <div className={styled.name_input}>
                        <label htmlFor="name">Delivery Address </label>
                        <p>Subtitle</p>
                      </div>
                      <div className={styled.email_input}>
                        <input
                          type="text"
                          name="delivery_address"
                          id="email"
                          placeholder="Baku "
                          value={form.delivery_address}
                          onChange={handleItemChange}
                          required
                        />
                        <Icon Name="map" />
                      </div>
                    </div>
                    <div className={styled.name}>
                      <div className={styled.name_input}>
                        <label htmlFor="name">Date & Time</label>
                        <p>Subtitle</p>
                      </div>
                      <div className={styled.email_input}>
                        <div className={styled.times}>
                          <input
                            type="date"
                            name="delivery_date"
                            placeholder="select the time"
                            value={form.delivery_date}
                            onChange={handleItemChange}
                          />
                          <input
                            type="time"
                            name="delivery_time"
                            placeholder="select the time"
                            value={form.delivery_time}
                            onChange={handleItemChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPartner;
