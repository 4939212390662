import { useMemo, useState } from "react";
import styles from "./index.module.scss";
import { Handle, Position } from "reactflow";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plusIcon.svg";
import { ReactComponent as BetweenIcon } from "../../../../../assets/icons/betweenIcon.svg";
import { ReactComponent as MoreIcon } from "../../../../../assets/icons/moreIcon.svg";
import { SUBCATEGORY_ENUM } from "../../conditions.data";
const CustomNode = ({ data }) => {
  const [showOptions, setShowOptions] = useState(false);

  const OPTIONS = useMemo(() => {
    return [
      {
        label: "Delete",
      },
    ];
  }, []);
  return (
    <div className={styles.element}>
      {data.type === SUBCATEGORY_ENUM.JOIN_INPUT ? (
        <>
          <Handle
            type="source"
            id={data.id + "--1"}
            className="react_flow_handle_input_top"
          />
          <Handle
            type="source"
            id={data.id + "--2"}
            className="react_flow_handle_input_bottom"
          />
        </>
      ) : (
        <Handle type="source" position={Position.Left} id={"1"} />
      )}
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>{data.icon}</div>
        <div className={styles.body}>
          <p className={styles.label}>{data.label}</p>
          {data.type === SUBCATEGORY_ENUM.INFO && (
            <p className={styles.info}>{data.info}</p>
          )}
          {data.type === SUBCATEGORY_ENUM.VALUE_INDICATOR && (
            <div className={styles.valueWrapper}>
              <input
                type="text"
                onChange={(e) =>
                  data.handleValueUpdate(data.id, e.target.value, 0)
                }
              />
              <p className={styles.valueType}>{data.valueType}</p>
            </div>
          )}
          {data.type === SUBCATEGORY_ENUM.BETWEEN_INPUT && (
            <div className={styles.betweenWrapper}>
              <div className={styles.valueWrapper}>
                <input
                  type="text"
                  onChange={(e) =>
                    data.handleValueUpdate(data.id, e.target.value, 0)
                  }
                />
                <p className={styles.valueType}>{data.valueType}</p>
              </div>
              <BetweenIcon />
              <div className={styles.valueWrapper}>
                <input
                  type="text"
                  onChange={(e) =>
                    data.handleValueUpdate(data.id, e.target.value, 1)
                  }
                />
                <p className={styles.valueType}>{data.valueType}</p>
              </div>
            </div>
          )}
          {data.type === SUBCATEGORY_ENUM.TEXTAREA && (
            <div className={styles.textAreaWrapper}>
              <p className={styles.info}>{data.info}</p>
              <textarea
                name=""
                placeholder="Placeholder"
                onChange={(e) =>
                  data.handleValueUpdate(data.id, e.target.value, 0)
                }
              ></textarea>
            </div>
          )}
          {data.type === SUBCATEGORY_ENUM.JOIN_INPUT && (
            <div className={styles.joinWrapper}>
              <div className={styles.valueWrapper}>
                <p className={styles.valueType}>Input 1</p>
                <input
                  type="text"
                  disabled
                  value={data.values[0]}
                  onChange={(e) =>
                    data.handleValueUpdate(data.id, e.target.value, 0)
                  }
                />
              </div>
              <div className={styles.valueWrapper}>
                <p className={styles.valueType}>Input 2</p>
                <input
                  type="text"
                  disabled
                  value={data.values[1]}
                  onChange={(e) =>
                    data.handleValueUpdate(data.id, e.target.value, 1)
                  }
                />
              </div>
            </div>
          )}
        </div>

        <div onClick={(e) => data.handleCreate(e)} className={styles.createBtn}>
          <PlusIcon />
        </div>
        <div
          onClick={(e) => data.handleCreateLeft(e)}
          className={styles.createBtnLeft}
        >
          <PlusIcon />
        </div>
      </div>

      <Handle type="target" position={Position.Right} />
      {data.value != "newOrder" && data.value != "deliveryCompleted" && (
        <div
          className={styles.editBtn}
          onClick={() => setShowOptions((prev) => !prev)}
        >
          <MoreIcon />
        </div>
      )}
      {showOptions && (
        <div className={styles.options}>
          {OPTIONS.map((el, idx) => (
            <p key={idx} onClick={() => data.handleDelete(data.id)}>
              {el.label}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomNode;
