import React, { useState, useCallback, useMemo, useRef } from "react";
import cloneDeep from "lodash/cloneDeep";
import styles from "./index.module.scss";
import Header from "./components/Header/Header";
import StartCreation from "./components/StartCreation/StartCreation";
import SelectCondition from "./components/SelectCondition/SelectCondition";
import { CONDITIONS_DATA, SUBCATEGORY_ENUM } from "./conditions.data";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
  updateEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import { useEffect } from "react";
import CustomNode from "./components/CustomNode/CustomNode";

const Conditions = () => {
  //-----------------------------------------------
  //Local States -- start
  const [showConditionSelect, setShowConditionSelect] = useState(false);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subCategoryTitle, setSubCategoryTitle] = useState("");
  const [clickPos, setClickPos] = useState({
    clientX: window.innerWidth / 2 - 200,
    clientY: window.innerHeight / 2 - 200,
  });
  const [isModalAbsolute, setIsModalAbsolute] = useState(false);
  const [isSaveDisableed, setIsSaveDisabled] = useState(true);
  //Local States -- end
  //-----------------------------------------------

  //-----------------------------------------------
  //React Flow actions -- start
  const edgeUpdateSuccessful = useRef(true);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [newNodeDirection, setNewNodeDirection] = useState(1);

  const nodesRef = useRef([]);
  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, type: "smoothstep" }, eds)),
    [setEdges]
  );

  //Edge Events handle deletion of connection
  //Edge Events -- start
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    edgeUpdateSuccessful.current = true;
  }, []);
  //Edge Events -- end
  useEffect(() => {
    if (nodes.length > 2) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
    setShowConditionSelect(false);
    setShowSubCategories(false);
    nodesRef.current = nodes;
  }, [nodes]);

  //Ressetting on unmount
  useEffect(() => {
    return () => {
      setNodes([]);
      setEdges([]);
      nodesRef.current = [];
    };
  }, []);

  //This Handles Edge Connections
  //Specially Updates the Join Type of Nodes' values
  useEffect(() => {
    if (edges.length > 0) {
      const lastEdgeConnection = edges[edges.length - 1];
      const sourceEl = edges.find((el) => el?.id == lastEdgeConnection.source);
      const targetEl = edges.find((el) => el?.id == lastEdgeConnection.target);
      if (sourceEl && targetEl) {
        if (sourceEl.data.type === SUBCATEGORY_ENUM.JOIN_INPUT) {
          const inputPos = lastEdgeConnection.sourceHandle.split("--")[1];
          handleValueChange(sourceEl.id, targetEl.data.value, inputPos - 1);
        }
      }
    }
  }, [edges]);
  //React Flow actions -- end
  //-----------------------------------------------

  //-----------------------------------------------
  //Header actions -- start
  const handleCancel = () => {
    setNodes([]);
    setEdges([]);
    setIsSaveDisabled(true);
    setIsModalAbsolute(false);
    setShowConditionSelect(true);
  };
  const handleSave = () => {
    // send to backend api save:
    const payload = {
      nodes,
      edges,
    };
    console.log("save", payload);
  };
  const handleCreate = () => {
    categoryClickHandler("newOrder");
    categoryClickHandler("deliveryCompleted", true);
    // setShowConditionSelect(true);
  };
  //Header actions -- end
  //-----------------------------------------------

  //-----------------------------------------------
  //Select Condition actions -- start
  const handleNewNodeCreate = (e, direction) => {
    setIsModalAbsolute(true);
    setNewNodeDirection(direction === "left" ? -1 : 1);
    setClickPos({
      clientX: e.clientX,
      clientY: e.clientY,
    });
    setShowConditionSelect(true);
  };
  const categoryClickHandler = (name, pushRight = false) => {
    console.log(name);
    const mainCategory = cloneDeep(CONDITIONS_DATA).find((el) =>
      el.subCategories.find((el) => el.value === name)
    );
    const subCategory = mainCategory.subCategories.find(
      (el) => el.value === name
    );
    const newNode = {
      id: (nodesRef.current.length + 1).toString(),
      position: {
        x: nodesRef.current.length
          ? nodesRef.current[nodesRef.current.length - 1].position.x +
            430 * newNodeDirection
          : window.innerWidth / 2 - 200,
        y: nodesRef.current.length
          ? nodesRef.current[nodesRef.current.length - 1].position.y
          : window.innerHeight / 2 - 200,
      },
      type: "customNode",
      data: {
        id: (nodesRef.current.length + 1).toString(),
        label: subCategory.label,
        icon: subCategory.icon,
        values: [],
        type: subCategory.type,
        handleCreate: (e) => handleNewNodeCreate(e, "right"),
        handleCreateLeft: (e) => handleNewNodeCreate(e, "left"),
        handleDelete: (id) => handleDeleteNode(id),
        handleValueUpdate: (id, value, pos) =>
          handleValueChange(id, value, pos),
        ...subCategory,
      },
    };
    if (pushRight) {
      newNode.position.x += 400;
      newNode.id += 1;
    }
    setNodes((prev) => [...prev, newNode]);
    setEdges((prev) => [...prev, newNode]);
  };
  const categoryMouseOverHandler = (name) => {
    const _data = CONDITIONS_DATA.find((el) => el.value === name);
    setSubCategoryData(_data.subCategories);
    setSubCategoryTitle(_data.label);
    setShowSubCategories(true);
  };
  const handleValueChange = (id, value, pos) => {
    const _updated = nodesRef.current.map((el) => {
      if (el.id == id) {
        el.data.values[pos] = value;
      }
      return el;
    });
    setNodes(cloneDeep(_updated));
  };
  const handleDeleteNode = (id) => {
    const _updated = nodesRef.current.filter((el) => el.id != id);
    console.log(_updated);
    if (_updated.length === 0) {
      setNodes([]);
      setEdges([]);
    } else {
      setNodes(cloneDeep(_updated));
    }
  };
  //Select Condition actions -- end
  //-----------------------------------------------

  //Right click handler on element -- start
  const handleRightClick = (e) => {
    e.preventDefault();
    console.log(e);
    setClickPos({
      clientX: e.clientX,
      clientY: e.clientY,
    });
    setIsModalAbsolute(true);
    setShowConditionSelect(true);
  };
  //Right click handler on element -- end

  return (
    <div className={styles.element} onContextMenu={handleRightClick}>
      <Header
        cancelHandler={handleCancel}
        saveHandler={handleSave}
        isSaveDisabled={isSaveDisableed}
      />
      <div className={styles.content}>
        {!showConditionSelect && !nodes.length && (
          <StartCreation handleCreate={handleCreate} />
        )}
        {showConditionSelect && (
          <SelectCondition
            isSearchable={true}
            isMain={true}
            isPosAbsolute={isModalAbsolute}
            positions={clickPos}
            title={"Conditions"}
            data={CONDITIONS_DATA}
            clickHandler={categoryClickHandler}
            mouseOverhandler={categoryMouseOverHandler}
          />
        )}
        {showSubCategories && (
          <SelectCondition
            isSearchable={false}
            isMain={false}
            isPosAbsolute={isModalAbsolute}
            positions={clickPos}
            title={subCategoryTitle}
            data={subCategoryData}
            clickHandler={categoryClickHandler}
            mouseOverhandler={categoryMouseOverHandler}
          />
        )}
        {nodes.length > 0 && (
          <ReactFlow
            nodes={nodes}
            edges={edges}
            edgeTypes={"step"}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            nodeTypes={nodeTypes}
          >
            <Controls />
            <MiniMap />
          </ReactFlow>
        )}
      </div>
    </div>
  );
};

export default Conditions;
