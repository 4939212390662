import React from "react";
import "./OrderInformation.scss";
import OrderIcons from "../../assets/order_iocns.svg";
import OrderTime from "../../assets/order_time.svg";
import OrderStar from "../../assets/order_star.svg";
import OrderVelo from "../../assets/order_velosi.svg";
import OrderCard from "../OrderCard/OrderCard";

const data = [
  {
    id: Math.random() * 1000,
    Images: OrderIcons,
    head: 125,
    title: "Active orders",
    bgColor: "#fff2e4",
    color: "#ef6820",
  },
  {
    id: Math.random() * 1000,
    Images: OrderTime,
    head: " 2.3 hrs",
    title: "Average delivery time",
    bgColor: "#FFE9ED",
    color: "#F63D68",
    border_color: "#FFE9ED",
  },
  {
    id: Math.random() * 1000,
    Images: OrderVelo,
    price: 24,
    head: 125,
    title: "Active couriers",
    bgColor: "#F3F1FF",
    border_color: "#F3F1FF",
    color: "#875BF7",
  },
  {
    id: Math.random() * 1000,
    Images: OrderStar,
    price: 25,
    title: "Avarage Courier Rating",
    bgColor: "#F0FDE2",
    border_color: "#F0FDE2",
    color: "#4CA30D",
    head: 4.5,
  },
];

const OrderInformation = () => {
  return (
    <aside>
      <div className="container">
        <div className="row">
          {data.length > 0 &&
            data.map((element, index) => (
              <OrderCard
                key={index}
                bgColor={element.bgColor}
                color={element.color}
                head={element.head}
                icons={element.Images}
                title={element.title}
                BorderColor={element.border_color}
              />
            ))}
        </div>
      </div>
    </aside>
  );
};

export default OrderInformation;
