import React, { useRef } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { RiFilter2Fill } from "react-icons/ri";
import styled from "./OrderList.module.scss";
import { FaEdit } from "react-icons/fa";
import { RiListSettingsLine } from "react-icons/ri";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import "primeicons/primeicons.css";

import {
  useGET_ALL_ORDERSQuery,
  useGET_ORDERMutation,
} from "../../../redux/features/api/Order";
import Icon from "../../Icon/Icon";
import clsx from "clsx";

const OrderList = () => {
  const menu = useRef(null);
  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Update",
          icon: "pi pi-file-edit",
          command: () => {},
        },
        {
          label: "Detail",
          icon: "pi pi-file",
          command: () => {},
        },
        {
          label: "Delete",
          icon: "pi pi-trash",
          command: () => {
            console.log("Delete");
          },
        },
      ],
    },
  ];

  const {
    data: order,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGET_ALL_ORDERSQuery();
  const [GET_ORDER] = useGET_ORDERMutation();

  const HandleBy_Id = (id) => {
    GET_ORDER(id);
    console.log(order);
  };
  return (
    <section className={styled.order_list}>
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-md-6 col-sm-12 g-2">
            <div className={styled.filter_buttons}>
              <button className={styled.activeBtn}>ALL</button>
              <button>Current</button>
              <button>Scheduled</button>
              <button>Completed</button>
              <button>Failed</button>
              <button>Failed</button>
              <button>Archived</button>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-sm-12 g-2 ">
            <div className={styled.filter_search}>
              <div className={styled.search_order}>
                <input type="search" placeholder="Search..." />
                <AiOutlineSearch />
              </div>
              <button className={styled.filtered_order}>
                <RiFilter2Fill />
                Filter
              </button>
            </div>
          </div>
          <div className="col-xl-12 py-4">
            <table class="table">
              <thead>
                <tr className={styled.head_table}>
                  <th scope="col">
                    <input type="checkbox" />
                  </th>
                  <th scope="col">Order Number</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Price</th>
                  <th scope="col">Delivery fee</th>
                  <th scope="col">Delivery Address</th>
                  <th scope="col">Distance</th>
                  <th scope="col">Est. Time</th>
                  <th scope="col">Driver</th>
                  <th scope="col">Status</th>
                  <th>
                    {" "}
                    <RiListSettingsLine size={18} />
                  </th>
                </tr>
              </thead>
              <tbody className={styled.tbody}>
                {isSuccess &&
                  order &&
                  order.data.map((order, index) => (
                    <tr key={`Order_id${order.id}`}>
                      <th scope="row">
                        <input className={styled.check} type="checkbox" />
                      </th>
                      <td>
                        <div className={styled.data_img}>
                          <p>{order.order_number || ""}</p>
                        </div>
                      </td>
                      <td>
                        <p className={styled.phone_number}>
                          {order.pickup.fullname || ""}
                        </p>
                      </td>
                      <td>
                        <p className={styled.phone_number}>
                          ${order.total_price || ""}
                        </p>
                      </td>
                      <td>
                        <button className={styled.phone_number}>
                          ${order.courier_fee || ""}
                        </button>
                      </td>
                      <td>
                        <p>{order.delivery.address || ""}</p>
                      </td>
                      <td>
                        <p>24KM</p>
                      </td>
                      <td>
                        <p>7:30 AM</p>
                      </td>
                      <td>
                        <p>{order.pickup?.fullname || ""}</p>
                      </td>
                      <td>
                        <button
                          className={`${
                            order?.status.status.name === "Unsigned"
                              ? styled.unsigned
                              : (() => {
                                  switch (order?.status.status.name) {
                                    case "On The Way":
                                      return "onthe_way";
                                    case "Picked-up":
                                      return "picked_up";
                                    case "Delivered":
                                      return "delivered";
                                    case "Failed":
                                      return "failed";
                                    case "Started":
                                      return "started";

                                    default:
                                      return null;
                                  }
                                })()
                          }`}
                        >
                          {order.status.status.name || ""}
                        </button>
                      </td>
                      <td>
                        <div className={styled.edit}>
                          <FaEdit />
                        </div>
                      </td>
                      <td>
                        <div className={styled.setting}>
                          <Menu model={items} popup ref={menu} />
                          <Button
                            className={
                              (clsx(
                                "p-padding-0 p-hover-icon-only  p-bg-transparent p-button-text p-hover-transparent p-button-animated-blink"
                              ),
                              styled.setting_btn)
                            }
                            label={<Icon Name="ellipsis-v" />}
                            onClick={(e) => menu.current.toggle(e)}
                          />
                        </div>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <section>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          // aria-labelledby={`staticBackdropLabel-${OrderDetail.id}`}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content custom_modal_width">
              <div className="modal-header">
                <h5
                  className="modal-title currier_modal"
                  // id={`staticBackdropLabel- ${OrderDetail.id}`}
                >
                  Orders Details
                </h5>
                <button
                  style={{
                    border: "1px solid #EAECF0",
                    borderRadius: "4px",
                    boxShadow: "none",
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row align-items-start g-4">
                    {order?.courier_fee || ""}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn ${styled.delete_btn}`}
                  data-bs-dismiss="modal"
                >
                  Delete data
                </button>
                <button type="button" className={`btn ${styled.edit_btn}`}>
                  Edit Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default OrderList;
