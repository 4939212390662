import React from "react";
import CurriersList from "../../Curriers/CurriersList";

const Couriers = () => {
  return (
    <div>
      <CurriersList />
    </div>
  );
};

export default Couriers;
